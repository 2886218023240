import { Component, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatInput } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { FeatherModule } from "angular-feather";
import { MatIcon } from "@angular/material/icon";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatOption } from "@angular/material/autocomplete";
import { imageIcon, SVGIcon } from "@progress/kendo-svg-icons";
import { MatSelect } from "@angular/material/select";
import { MatTooltip } from "@angular/material/tooltip";
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from "@angular/material/datepicker";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { CommonModule } from '@angular/common';
import { RouterLink, Router } from '@angular/router';
import { FileUploadModule } from '@iplab/ngx-file-upload';


@Component({
  selector: 'app-creategroup',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatRadioGroup, FileUploadModule,
    MatRadioButton,
    MatInput,
    MatDialogModule,
    MatButtonModule,
    MatLabel,
    MatIcon,
    MatSlideToggle,
    MatOption,
    MatSelect,
    MatTooltip,
    MatSuffix,
    HttpClientModule,
    CommonModule,
    RouterLink
  ],
  templateUrl: './creategroup.component.html',
  styleUrl: './creategroup.component.scss'
})
export class CreategroupComponent implements OnInit {
  public value: Date;
  public imageSVG: SVGIcon = imageIcon;
  typicalpaces: any[] = [];
  rideTypes: any[] = [];
  levels: any[] = [];
  mileages: any[] = [];
  logoBase64: string | null = null;
  coverPhotoBase64: string | null = null; // Added cover photo base64

  states: any[] = [];
  cities: any[] = [];

  public multiple: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CreategroupComponent>,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
  ) {
    this.createRideForm = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', Validators.required], 
      la_TypicalPace_ID: ['', Validators.required], // Assuming required
      la_RidingType_ID: ['', Validators.required], // Assuming required
      la_RidingLevel_ID: ['', Validators.required], // Assuming required
      la_AnnualMileage_ID: ['', Validators.required], // Assuming required
      invitedRidersOnly: [false], // Assuming optional
      adminApproval: [false], // Assuming optional
      allowMemberPost: [false], // Assuming optional
      amount:  [''], // Assuming required and must be >= 0
      logo: [''], // Assuming optional
      coverphoto: [''], // Assuming optional
      locationComment: [''], // Assuming optional
      url: [''],  // Assuming optional
      stateId: ['', Validators.required], 
      cityId: ['', Validators.required], 
      socialLinks: [''],
      x: [''], 
      instagram: [''], 
      faceBook: [''], 

    });
  }

  // Password Hide
  hide = true;

  // Form
  createRideForm: FormGroup;

  onFileSelected(event: Event, controlName: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        this.createRideForm.patchValue({
          [controlName]: base64String
        });
        console.log(`${controlName} base64String:`, base64String);
        if (controlName === 'logo') {
          this.logoBase64 = base64String;
        } else if (controlName === 'coverphoto') {
          this.coverPhotoBase64 = base64String; // Assign base64String to cover photo
        }
      };
      reader.readAsDataURL(file);
    }
  }

  onRestrictionChange(controlName: string, event: any): void {
    if (controlName === 'invitedRidersOnly' && event.value === true) {
      this.createRideForm.patchValue({ adminApproval: false });
    } else if (controlName === 'adminApproval' && event.value === true) {
      this.createRideForm.patchValue({ invitedRidersOnly: false });
    }
  }
  
  onSubmit() {
    if (this.createRideForm.valid) {
      console.log(this.createRideForm.value);
      var cbPartnerId = "";
      var userobj = localStorage.getItem("userObj");
      if (userobj) {
        try {
          var parsedUserObj = JSON.parse(userobj);

          cbPartnerId = parsedUserObj.cbPartnerId;
          console.log(parsedUserObj);
        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
        }
      } else {
        console.warn("No userObj found in localStorage.");
      }

      // Construct the login object
      const requestBody = {
        "amount": this.createRideForm.get('amount')?.value,
        "description": this.createRideForm.get('description')?.value,
        "image": "", // You may need to handle image upload separately
        "allowMemberPost": this.createRideForm.get('allowMemberPost')?.value,
        "bankAccount": true, // Assuming this is hardcoded to true
        "locationComment": this.createRideForm.get('locationComment')?.value,
        "logo": "", // You may need to handle logo upload separately
        "name": this.createRideForm.get('name')?.value,
        "socialLinks": this.createRideForm.get('socialLinks')?.value,
        "coverphoto": "", // Use cover photo base64 here
        "url": this.createRideForm.get('url')?.value,
        "la_TypicalPace_ID": this.createRideForm.get('la_TypicalPace_ID')?.value,
        "adminApproval": this.createRideForm.get('adminApproval')?.value,
        "invitedRidersOnly": this.createRideForm.get('invitedRidersOnly')?.value,
        "la_AnnualMileage_ID": this.createRideForm.get('la_AnnualMileage_ID')?.value,
        "cityId":  this.createRideForm.get('cityId')?.value,
        "stateId":  this.createRideForm.get('stateId')?.value,
        "x":  this.createRideForm.get('x')?.value,
        "faceBook":  this.createRideForm.get('faceBook')?.value,
        "instagram":  this.createRideForm.get('instagram')?.value,
        "la_RidingLevel_ID": this.createRideForm.get('la_RidingLevel_ID')?.value, // Directly capture the array of IDs
        "la_RidingType_ID": this.createRideForm.get('la_RidingType_ID')?.value, 
      };


      // {
      //   "ridingLevel": [],
      //   "ridingType": []
      // }

      var obj =
        console.log("userobjuserobj", userobj)
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

      this.http.post(BASE_URL + ApiEndpoints.createGroup, requestBody, { headers: headers }).subscribe(
        (res: any) => {
          if (res) {
            alert("Group created successfully");
            // this.router.navigate(['/dashboard']);
            window.location.reload();

          } else {
            // Handle other cases where response is not as expected
            console.error("Unexpected response:", res);
            alert("Error, Failed");
          }
        },
        (error) => {
          if (error.status === 401) {
            // Handle 401 Unauthorized error
            console.log("Unauthorized:", error.status);
            alert("Unauthorized");
          } else if (error.status === 500) {
            // Handle 401 Unauthorized error
            console.log("Unauthorized:", error.status);
            alert("Internal server error");
          } else {
            // Handle other errors
            console.error("Error logging in:", error);
            alert("Failed creating record. Please try again later.");
          }
        }
      );

    } else {
      this.createRideForm.markAllAsTouched(); // Mark all fields as touched to display validation errors
      console.log('Form is invalid. Please check the fields.');
    }
  }

  // Correctly place the onNoClick method outside the constructor
  onNoClick() {
    this.dialogRef.close();
    // this.loadPurposes()
  }

  ngOnInit(): void {
    this.loadtypicalPaces();
    this.loadRideType();
    this.loadLevel();
    this.loadMileage();
    this.loadstates();
    this.loadCity();

  }

  loadstates() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveStates, { headers: headers }).subscribe(
      (data: any) => {
        this.states = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  loadCity() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveCity, { headers: headers }).subscribe(
      (data: any) => {
        this.cities = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  loadtypicalPaces() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveridingPaceList, { headers: headers }).subscribe(
      (data: any) => {
        this.typicalpaces = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }

  loadRideType() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveridingTypeList, { headers: headers }).subscribe(
      (data: any) => {
        this.rideTypes = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  loadLevel() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveridingLevelList, { headers: headers }).subscribe(
      (data: any) => {
        this.levels = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }



  loadMileage() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveAnnualMileage, { headers: headers }).subscribe(
      (data: any) => {
        this.mileages = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }




}
