<mat-card
  class="trinta-card pm-stat-card mb-25 border-none d-block text-center"
>
  <mat-card-content  class="d-flex flex-column justify-content-between" >

    <div>
      <span class="d-block fw-medium">
                   Connect to Strava to identify potential members and more
                </span>

      <button mat-raised-button color="primary" class="mt-20 border-radius fw-bold">GO PRO</button>
    </div>

    <div>
      <img src="/assets/images/Road%20cycling-bro.svg" alt="Illustration of a cycling group">

    </div>
  </mat-card-content>
</mat-card>
