


<div class="sign-in-area">


  
    <div class="branding-section">
      <img src="/assets/logo/lacorsalogo.svg" alt="La Corsa official logo" aria-hidden="true">
      <h3 class="description">It's time to ride!</h3>
    </div>
    <div class="d-table login logisection">
      <img src="/assets/logo/Logo-WithText-color.svg" alt="La Corsa official logo" aria-hidden="true">
      <div class="d-table-cell login-background">
  
  
  
  
  
  
  
  
  
  
  
  
        
        <div class="trinta-form ml-auto mr-auto border-radius loginpageform">
          <div class="title text-left">
            <h3>Managing your bike club has never been easier.</h3>
          </div>
  
          <div class="signin-with-socials">
          </div>
          <form [formGroup]="authForm" (ngSubmit)="onSubmit()" class="signform">
            <div class="form-group">
                <label class="label d-block fw-medium mb-10">
                    Email Address
                </label>
                <mat-form-field>
                    <input matInput type="email" id="email" formControlName="email">
                </mat-form-field>
                <div class="error text-danger" *ngIf="authForm.get('email')!.hasError('required') && authForm.get('email')!.touched">
                    Email is required.
                </div>
                <div class="error text-danger" *ngIf="authForm.get('email')!.hasError('email') && authForm.get('email')!.touched">
                    Please enter a valid email address.
                </div>
            </div>
            <div class="form-group">
                <label class="label d-block fw-medium mb-10">
                    Your Password
                </label>
                <mat-form-field>
                    <input matInput [type]="hide ? 'password' : 'text'" id="password" formControlName="password">
                    <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <span class="material-symbols-outlined">
                            {{hide ? 'visibility_off' : 'visibility'}}
                        </span>
                    </button>
                </mat-form-field>
                <div class="error text-danger" *ngIf="authForm.get('password')!.hasError('required') && authForm.get('password')!.touched">
                    Password is required.
                </div>
                <div class="error text-danger" *ngIf="authForm.get('password')!.hasError('minlength') && authForm.get('password')!.touched">
                    Password must be at least 8 characters long.
                </div>
            </div>
            <div class="info d-flex justify-content-between align-items-center">
                <mat-checkbox color="primary">Remember me</mat-checkbox>
                <a href="/authentication/forgot-password" routerLink="/authentication/forgot-password" class="d-inline-block text-primary">
                  Forgot your password?
                </a>
              </div>
            <button mat-button type="submit">
                Sign In
            </button>
        </form>
        </div>
      </div>
    </div>
  </div>
  













<!-- 
<body class="background-image-css">
  <div class="sign-in-area">
      <div class="d-table">
          <div class="d-table-cell">
              <div  class="trinta-form ml-auto mr-auto bg-white border-radius content">
                  <div class="title text-center">
                      <h3>
                          Sign In
                      </h3>
                      <p class="text-black fs-15">
                          Don't have an account yet? <a routerLink="/authentication/sign-up" class="text-primary fw-medium">Sign up here</a>
                      </p>
                  </div>
                 
                  <form [formGroup]="authForm" (ngSubmit)="onSubmit()" class="signform">
                      <div class="form-group">
                          <label class="label d-block fw-medium mb-10">
                              Email Address
                          </label>
                          <mat-form-field>
                              <input matInput type="email" id="email" formControlName="email">
                          </mat-form-field>
                          <div class="error text-danger" *ngIf="authForm.get('email')!.hasError('required') && authForm.get('email')!.touched">
                              Email is required.
                          </div>
                          <div class="error text-danger" *ngIf="authForm.get('email')!.hasError('email') && authForm.get('email')!.touched">
                              Please enter a valid email address.
                          </div>
                      </div>
                      <div class="form-group">
                          <label class="label d-block fw-medium mb-10">
                              Your Password
                          </label>
                          <mat-form-field>
                              <input matInput [type]="hide ? 'password' : 'text'" id="password" formControlName="password">
                              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                  <span class="material-symbols-outlined">
                                      {{hide ? 'visibility_off' : 'visibility'}}
                                  </span>
                              </button>
                          </mat-form-field>
                          <div class="error text-danger" *ngIf="authForm.get('password')!.hasError('required') && authForm.get('password')!.touched">
                              Password is required.
                          </div>
                          <div class="error text-danger" *ngIf="authForm.get('password')!.hasError('minlength') && authForm.get('password')!.touched">
                              Password must be at least 8 characters long.
                          </div>
                      </div>
                      <div class="info d-flex justify-content-between align-items-center">
                          <mat-checkbox color="primary">
                              Remember me
                          </mat-checkbox>
                          <a routerLink="/authentication/forgot-password" class="d-inline-block text-primary">
                              Forgot your password?
                          </a>
                      </div>
                      <button mat-button type="submit">
                          Sign In
                      </button>
                  </form>
              </div>
          </div>
      </div>
  </div>
  </body>




   -->