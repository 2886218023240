<!-- Product Details Card -->
<mat-card
    class="trinta-card product-details-card mb-25 bg-white border-none d-block"
>
    <mat-card-content>
        <div class="row">
            <div class="col-xxxl-7 col-lg-6 col-md-12">
                <div class="product-details-content">


                    <div class="row" style="width: 100%;">


                        <div class="row" style="width: 13%;">
                            <img [src]="logo" class="profile-img" style="width: 140px; height: 80px; border-radius: 50%;">

                            <!-- <img src="../../../../assets/logo.png" style="height: 80px;"> -->

                        </div>
                        <div class="row" style="width: 87%;">

                            <div class="title d-flex align-items-center justify-content-between">




                                <h3 class="mb-0">
                                    {{ name }}
                                </h3>
                                <div class="action d-flex align-items-center">
                                    <button type="button" class="btn btn-success elitebutton">Elite</button>
                                </div>
                            </div>
                            <span class="category text-body d-block txtinvites">
                              {{ invites }}
                            </span>
                           
                        </div>

                        <div class="ratings lh-1 d-flex align-items-center">
                            <svg class="dateicon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z"/></svg>
                            <span class="d-block fs-14 text-primary">
                                Created, May 2024
                            </span>

                            <span class="d-block fs-14 text-primary" style="float: right; padding-left: 60%;">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-301q99-80 149.5-154T680-594q0-90-56-148t-144-58q-88 0-144 58t-56 148q0 65 50.5 139T480-301Zm0 101Q339-304 269.5-402T200-594q0-125 78-205.5T480-880q124 0 202 80.5T760-594q0 94-69.5 192T480-200Zm0-320q33 0 56.5-23.5T560-600q0-33-23.5-56.5T480-680q-33 0-56.5 23.5T400-600q0 33 23.5 56.5T480-520ZM200-80v-80h560v80H200Zm280-520Z"/></svg>
                            </span>
                            {{ locationComment }}
                        </div>
                    </div>



                    <span class="sub-title d-block fs-14 fw-medium">
                        About
                    </span>
                    <p class="fs-15 text-black description">
                        {{ description }}
                    </p>


                    <div class="row owneradmin">
                        <div class="owner">
                            <span class="d-block fs-14 text-primary txttitle">
                                Owner
                            </span>
                            <div class="row ownersec">
                                <div class="profile">
                                    <!-- <div class="row" style="width: 13%;">
                                        <img [src]="profilePhoto" class="profile-img" style="width: 120px; border-radius: 50%;">
             -->
                                        <!-- <img src="../../../../assets/logo.png" style="height: 80px;"> -->
        
                                    <!-- </div> -->
                                    <img src="assets/pr1.png" class="profile-img" style="width: 50px;">
                                    <span class="profile-name">{{groupAdmin}}</span>
                                </div>
                            </div>
                        </div>
                    
                        <div class="admin">
                            <span class="d-block fs-14 text-primary txttitle">
                                Admins
                            </span>
                            <div class="row adminsec">
                                <div class="profile">
                                    <img src="assets/pr1.png" class="profile-img" style="width: 50px;">
                                    <span class="profile-name">Iggo Wilkins</span>
                                </div>
                                <div class="profile">
                                    <img src="assets/pr1.png" class="profile-img" style="width: 50px;">
                                    <span class="profile-name">Wiggo Wilkins</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    


                 


                    <div class="ratings lh-1 d-flex align-items-center ridingtypessec">
                        <span class="d-block fs-14 text-primary txttitle">
                            Ride Types
                        </span>
                    </div>

                    <div class="ratings lh-1 d-flex align-items-center">
                        <span class="d-block fs-14 text-primary typesbtns">
                            {{la_RidingType}}
                        </span>
                       
                    </div>
                    


                    <div class="ratings lh-1 d-flex align-items-center membershipfee">
                        <span class="d-block fs-14 text-primary membershiptxt">
                            Membership fee
                        </span>
                    </div>

                    <div class="ratings lh-1 d-flex align-items-center amonttxtsec">
                        <p>${{amount}} Monthly</p>
                    </div>

                    <!-- <div class="ratings lh-1 d-flex align-items-center amonttxtsec">
                        <p *ngIf="amount > 0">${{ amount}} Monthly</p>
                        <p *ngIf="amount === 0">No Limit</p>
                      </div> -->
                   

                </div>
            </div>
            <div class="col-xxxl-5 col-lg-6 col-md-12">
                <div class="product-details-image">
                    <button mat-stroked-button class="editproflesec" (click)="openDialog(EditgroupdetailsComponent)" style="border:1px solid var(--primaryColor)">
                      <mat-icon>edit</mat-icon>
                        Edit Profile
                      </button>

                      <img [src]="image" class="profile-img" style="width: 100%; height: 380px;">

                    <!-- <img src="assets/group.png" class="profile-img" style="width: 100%; height: 380px;"> -->

                    <div class="ratings lh-1 d-flex align-items-center socialheadersec">
                        <span class="d-block fs-14 text-primary">
                            Socials
                        </span>
                    </div>

                    <div class="socilalinks lh-1 d-flex align-items-center">
                        <!-- <div class="info d-flex justify-content-between align-items-center">
                            <mat-checkbox color="primary">Remember me</mat-checkbox>
                            <a href="/authentication/forgot-password" routerLink="/authentication/forgot-password" class="d-inline-block text-primary">
                              Forgot your password?
                            </a>
                          </div> -->
                          <a [href]="faceBook" target="_blank" class="d-inline-block text-primary">
                            <span class="d-flex align-items-center fs-14 text-primary socialicons">
                                <mat-icon class="social-icon">facebook</mat-icon>
                                <span class="social-text">Facebook</span>
                            </span>
                        </a>

                        <a [href]="x" target="_blank" class="d-inline-block text-primary">
                            <span class="d-flex align-items-center fs-14 text-primary socialicons">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                                    <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
                                </svg>
                                <span class="social-text">Twitter</span>
                            </span>
                        </a>

                        <a [href]="instagram" target="_blank" class="d-inline-block text-primary">
                            <span class="d-flex align-items-center fs-14 text-primary socialicons">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                                    <path d="M360-400h400L622-580l-92 120-62-80-108 140Zm-40 160q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320Zm0-80h480v-480H320v480ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z"/>
                                </svg>
                                <span class="social-text">Instagram</span>
                            </span>
                        </a>
                       
                     
                    </div>
                    
                    

                </div>
            </div>

        </div>
    </mat-card-content>
</mat-card>

<!-- Reviews -->
<!-- <app-reviews /> -->

<!-- Outlets Details -->
<!-- <app-outlets-details /> -->

