import { Component } from '@angular/core';
import {MatCard, MatCardContent, MatCardHeader, MatCardModule, MatCardTitle} from "@angular/material/card";
import {MatList, MatListItem, MatListModule} from "@angular/material/list";
import {DatePipe} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatMiniFabButton} from "@angular/material/button";
import {Router} from "@angular/router";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-ride-route-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatListModule,
    DatePipe,
    MatIcon,
    MatMiniFabButton,
    MatTooltip
  ],
  templateUrl: './ride-route-list.component.html',
  styleUrl: './ride-route-list.component.scss'
})
export class RideRouteListComponent {
  constructor(private router: Router){}


  openRouteListPage() {
    this.router.navigate(['/dashboard/routes'])
  }
}
