<div class="title text-center">
  <h2 mat-dialog-title class="fw-semibold">Invite Member</h2>
  <button mat-mini-fab color="primary" (click)="onNoClick()" class="cancel">
    <mat-icon>
      close
    </mat-icon>
  </button>
</div>
  <div mat-dialog-content>
    <form  [formGroup]="inviteMemberForm" (ngSubmit)="onSubmit()" class="d-flex flex-column">

      <mat-form-field>
        <mat-label>Search Member</mat-label>

        <input matInput type="text">
      </mat-form-field>
        <p class="text-center">OR</p>
      <mat-form-field>
        <mat-label>Enter email</mat-label>

        <input matInput type="text" placeholder="janedoe@yahoo.com">
      </mat-form-field>




      <div class="row">
        <!--      <button mat-stroked-button color="primary" type="submit" class="col-sm-5 mr-5">Save Draft</button>-->
        <button mat-raised-button color="primary" type="submit" class="col-sm-4 ml-auto mr-auto">Invite Member</button>
      </div>

    </form>
</div>
