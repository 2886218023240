<div class="forgot-password-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div
                class="trinta-form ml-auto mr-auto bg-white border-radius"
            >
                <div class="title text-center">
                    <h3>
                        Forgot Password!
                    </h3>
                    <p class="text-black fs-15">
                        Enter the email address you used when you joined and we'll send you instructions to reset your password.
                    </p>
                </div>
                <form>
                    <div class="form-group">
                        <mat-label>Email Address</mat-label>
                        <mat-form-field>
                            <input matInput class="email" type="email" id="email">
                        </mat-form-field>
                    </div>
                    <button mat-button type="button" style="background-color: #da1884;">
                        SUBMIT
                    </button>
                    <div class="text-center">
                        <a routerLink="/authentication" class="text-primary">
                            Back To Sign In
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>