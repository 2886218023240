<div class="title text-center">
    <h2 mat-dialog-title class="fw-semibold">Reschedule Ride</h2>
    <button mat-mini-fab color="primary" (click)="onNoClick()" class="cancel">
      <mat-icon>
      close
      </mat-icon>
    </button>
  </div>
  
  
  <div mat-dialog-content>
    <form [formGroup]="createRideForm" (ngSubmit)="onSubmit()" class="d-flex form-group flex-column">
      
     
     
      <mat-form-field class="custom-form-field" style="display: none !important;">
        <mat-select formControlName="ridinggroupId" id="ridinggroupId" name="ridinggroupId">
          <mat-option *ngFor="let group of groups" [value]="group.la_RidingGroup_ID">{{ group.name }}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field class="custom-form-field" style="display: none !important;">
        <input matInput formControlName="rideName" id="rideName" name="rideName">
      </mat-form-field>
  
  
      <mat-form-field class="custom-form-field" style="display: none !important;">
        <input matInput formControlName="description" id="description" name="description">

        <!-- <textarea matInput formControlName="description" id="description" name="description" rows="4"  maxlength="160"></textarea> -->
      </mat-form-field>
  
    
      <div class="row" class="custom-form-field" style="padding-top: 12px;">
        <mat-form-field class="col-sm-6" style="padding-right: 4px;">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="date"  id="date" [value]="displayDate" (dateChange)="onDateChange($event)">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
  
      
  
          <mat-form-field class="col-sm-6" style="padding-left: 4px;">
            <mat-label>Start Time</mat-label>
            <input matInput formControlName="time" type="time" id="time" name="time" rows="5">
  
          </mat-form-field>
      </div>
  
      <mat-label>End Time</mat-label>
      <div class="row custom-form-field">
        <mat-form-field class="col-sm-6">
            <input matInput formControlName="endTime" type="time" id="endTime" name="endTime">
        </mat-form-field>
      </div>
  
  
    
      <mat-form-field  class="custom-form-field" style="display: none !important;">
        <input matInput type="text" formControlName="startLocation" id="startLocation" name="startLocation">
      </mat-form-field>
  
      <div class="row custom-form-field"  style="padding-top: 16px; display: none;">
        <mat-form-field class="col-sm-6">
          <mat-label>State</mat-label>
          <mat-select formControlName="stateId" id="stateId" name="stateId">
            <mat-option *ngFor="let state of states" [value]="state.id">{{ state.name }}</mat-option>
          </mat-select>
          <!-- <input matInput type="text" formControlName="stateId" id="stateId" name="stateId"> -->
        </mat-form-field>
        <mat-form-field class="col-sm-6">
          <mat-label>City</mat-label>
          <mat-select formControlName="cityId" id="cityId" name="cityId">
            <mat-option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</mat-option>
          </mat-select>
          <!-- <input matInput type="text" formControlName="cityId" id="cityId" name="cityId"> -->
        </mat-form-field>
      </div>
  
  
      <mat-form-field class="custom-form-field" style="display: none !important;">
        <input matInput type="text" formControlName="route" id="route" name="route">
      </mat-form-field>
  
      <mat-form-field class="custom-form-field" style="display: none !important;">
        <input matInput type="text" formControlName="link" id="link" name="link">
        <mat-icon matSuffix>link</mat-icon>
      </mat-form-field>
  
        <mat-form-field class="custom-form-field" style="display: none !important;">
        <mat-select formControlName="levelId" id="levelId" name="levelId">
          <mat-option *ngFor="let level of levels" [value]="level.id">{{ level.name }}</mat-option>
        </mat-select>
      </mat-form-field>
  
  
      <div class="row" style="padding-top: 16px; padding-bottom: 25px; display: none;">
        <div class="col-sm-6">
            <mat-label>Is this a private ride ?</mat-label>
      </div>
        <div class="row col-sm-6">
          <mat-radio-group aria-label="Select an option" class="col-sm-6 d-flex justify-content-between flex-wrap" formControlName="privacyId" id="privacyId" name="privacyId">
            <mat-radio-button value="1000000">Yes</mat-radio-button>
            <mat-radio-button value="1000000">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      
  
      <mat-form-field class="custom-form-field" style="display: none !important;">
        <input matInput formControlName="limit" id="limit" name="limit">
      </mat-form-field>
  
  
      <mat-form-field class="custom-form-field" style="display: none !important;">
        <mat-select formControlName="preferredBikeTypesId" id="preferredBikeTypesId" name="preferredBikeTypesId">
          <mat-option *ngFor="let rideType of rideTypes" [value]="rideType.id">{{ rideType.name }}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field class="custom-form-field" style="display: none !important;">
        <input matInput  type="number" formControlName="distance" id="distance" name="distance">
      </mat-form-field>
  
      <div class="row custom-form-field" style="padding-top: 16px; display: none;">
        <mat-form-field class="col-sm-6">
          <mat-label>Pace</mat-label>
          <mat-select formControlName="paceId" id="paceId" name="paceId">
            <mat-option *ngFor="let pace of paces" [value]="pace.id">{{ pace.name }}</mat-option>
  
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-sm-6">
          <mat-label>Purpose</mat-label>
          <mat-select formControlName="purposeId" id="purposeId" name="purposeId">
            <mat-option *ngFor="let purpose of purposes" [value]="purpose.id">{{ purpose.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
  
      <mat-form-field class="d-flex justify-content-end custom-form-field" style="display: none !important;">
        <input matInput formControlName="rideLeader" name="rideLeader" id="rideLeader">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
  
      <div class="upload-button-container" style="display: none !important;">
        <input type="file" (change)="onFileChange($event)" />
      <mat-icon class="upload-icon">cloud_upload</mat-icon>
      </div>
      
      <mat-form-field class="d-flex justify-content-end custom-form-field" style="display: none !important;">
        <input matInput formControlName="rideId" name="rideId" id="rideId">
      </mat-form-field>
      <div class="button-container submitbtn">
        <button mat-raised-button color="primary" type="submit" class="col-sm-5">Reschedule Ride</button>
      </div>
  
    </form>
  </div>
  