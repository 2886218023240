import { Component,OnInit, Inject } from '@angular/core';
import { MatDialogRef,MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup,ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatInput } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { FeatherModule } from "angular-feather";
import { MatIcon } from "@angular/material/icon";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatOption } from "@angular/material/autocomplete";
import { DateAdapter } from '@angular/material/core';
import { MatSelect } from "@angular/material/select";
import { MatTooltip } from "@angular/material/tooltip";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { CommonModule } from '@angular/common';
import { RouterLink, Router } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { provideNativeDateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-ridereditroute',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatRadioGroup,
    MatRadioButton,
    MatInput,
    MatDialogModule,
    MatButtonModule,
    MatLabel,
    MatIcon,
    MatSlideToggle,
    MatOption,
    MatSelect,
    MatTooltip,
    MatSuffix,
    HttpClientModule,
    CommonModule,
    RouterLink,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    NgxMaterialTimepickerModule,
  ],

  providers: [provideNativeDateAdapter()],
  templateUrl: './ridereditroute.component.html',
  styleUrl: './ridereditroute.component.scss'
})
export class RidereditrouteComponent {

  paces: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  purposes: any[] = [];
  levels: any[] = [];
  recurrences: any[] = [];
  inoutdoors: any[] = [];
  groups: any[] = [];
  rideTypes: any[] = [];
  selectedFile: File | null = null;
  base64String: string = '';
  displayDate: string | null = null;
  rideId: string | null = null;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RidereditrouteComponent>,
    private http: HttpClient,
    private router: Router,
    private dateAdapter: DateAdapter<Date>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.createRideForm = this.fb.group({
      rideId: [this.data.rideId],
      rideName: [this.data?.name || '', Validators.required],
      description: [this.data?.description],
      ridinggroupId: [this.data?.ridinggroupId || '', Validators.required],
      startLocation: [this.data?.startlocation || '', Validators.required],
      cityId: [this.data?.cityId || '', Validators.required],
      stateId: [this.data?.stateId || '', Validators.required],
      route: [this.data?.route || '', Validators.required],
      limit: [this.data?.size || '', Validators.required],
      endTime: [this.data?.endTime || '', Validators.required],
      privacyId: [this.data?.privacyId || ''],
      levelId: [this.data?.levelId || '', Validators.required],
      distance: [this.extractNumberFromString(data?.distance) || '', Validators.required],
      link: [this.data?.link || '', Validators.required],
      paceId: [this.data?.paceId || '', Validators.required],
      purposeId: [this.data?.purposeId || '', Validators.required],
      date: [this.data?.date || '', Validators.required],
      time: [this.data?.time || '', Validators.required],
      recurrenceId: [this.data?.recurrenceId || ''],
      preferredBikeTypesId: [this.data?.preferredBikeTypesId || ''],
      rideLeader: [this.data?.rideLeader || '', Validators.required] // Add default values or validators as needed
    });
    this.dateAdapter.setLocale('en-GB'); 
  }


  extractNumberFromString(input: string): number | null {
    const match = input.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  }
  
  async onSubmit() {
    console.log('Form submit initiated.');
    if (this.createRideForm.valid) {
      if (this.selectedFile) {
        try {
          this.base64String = await this.convertFileToBase64(this.selectedFile);
          console.log('Base64 conversion successful:', this.base64String);
          this.createRideForm.patchValue({
            rideImage: this.base64String
          });
          this.submitForm();
        } catch (error) {
          console.error('Error converting file to base64:', error);
          this.submitForm();
        }
      } else {
        console.log('No file selected');
        this.submitForm(); // Proceed without the image if no file is selected
      }
    } else {
      console.log('Form is invalid. Please check the fields.');
    }
  }

  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  submitForm() {
    const ridinggroupId = this.createRideForm.get('ridinggroupId')?.value;
    const rideName = this.createRideForm.get('rideName')?.value;
    const startLocation = this.createRideForm.get('startLocation')?.value;
    const cityId = this.createRideForm.get('cityId')?.value;
    const stateId = this.createRideForm.get('stateId')?.value;
    const route = this.createRideForm.get('route')?.value;
    const limit = this.createRideForm.get('limit')?.value;
    const privacyId = this.createRideForm.get('privacyId')?.value;
    const preferredBikeTypesId = this.createRideForm.get('preferredBikeTypesId')?.value;
    const levelId = this.createRideForm.get('levelId')?.value;
    const distance = this.createRideForm.get('distance')?.value;
    const paceId = this.createRideForm.get('paceId')?.value;
    const purposeId = this.createRideForm.get('purposeId')?.value;
    const date = this.createRideForm.get('date')?.value;
    const time = this.createRideForm.get('time')?.value;
    const endTime = this.createRideForm.get('endTime')?.value;
    const link = this.createRideForm.get('link')?.value;
    const rideLeader = this.createRideForm.get('rideLeader')?.value;
    const description = this.createRideForm.get('description')?.value;
    const rideId = this.createRideForm.get('rideId')?.value;


    
    var cbPartnerId = "";
    var userobj = localStorage.getItem("userObj");
    if (userobj) {
      try {
        var parsedUserObj = JSON.parse(userobj);
        cbPartnerId = parsedUserObj.cbPartnerId;
        console.log(parsedUserObj);
      } catch (error) {
        console.error("Error parsing JSON from localStorage:", error);
      }
    } else {
      console.warn("No userObj found in localStorage.");
    }

    // Construct the request body
    const requestBody = {
      ridinggroupId: ridinggroupId,
      rideName: rideName,
      startLocation: startLocation,
      cityId: cityId,
      stateId: stateId,
      route: route,
      limit: limit,
      privacyId: privacyId,
      preferredBikeTypesId: preferredBikeTypesId,
      levelId: levelId,
      distance: distance,
      paceId: paceId,
      endTime: endTime,
      purposeId: purposeId,
      cbpartnerId: cbPartnerId,
      recurrenceId: 1000000,
      date: date,
      time: time,
      isPost: true,
      link: link,
      rideLeader: rideLeader,
      rideId: rideId,
      description: description
    };

    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.post(BASE_URL + ApiEndpoints.createRide, requestBody, { headers: headers }).subscribe(
      (res: any) => {
        if (res) {
          alert("Ride updated successfully");
          window.location.reload();
        } else {
          console.error("Unexpected response:", res);
          alert("Error, Failed");
        }
      },
      (error) => {
        if (error.status === 401) {
          console.log("Unauthorized:", error.status);
          alert("Unauthorized: Invalid username or password");
        } else if (error.status === 500) {
          console.log("Internal server error:", error.status);
          alert("Internal server error");
        } else {
          console.error("Error logging in:", error);
          alert("Error creating ride failed. Please try again later.");
        }
      }
    );
  }

  ngOnInit(): void {
    this.loadMygroups();
    this.loadPaces();
    this.loadstates();
    this.loadCity();
    this.loadPurpose();
    this.loadlevels();
    this.loadOutinDoor();
    this.ridetypes();
  }

   // Password Hide
   hide = true;
   createRideForm: FormGroup;

   // Form
 
   onFileChange(event: Event) {
     const input = event.target as HTMLInputElement;
     if (input.files && input.files[0]) {
       this.selectedFile = input.files[0];
     }
   }
   onDateChange(event: any): void {
     const date = event.value;
     console.log('Raw date value:', date); // Debugging log
     if (date) {
       const formattedDate = this.formatDate(date);
       console.log('Formatted date value:', formattedDate); // Debugging log
       this.displayDate = formattedDate;
       this.createRideForm.get('date')?.setValue(date);
     }
   }
   
   private formatDate(date: Date): string {
     const year = date.getFullYear();
     const month = ('0' + (date.getMonth() + 1)).slice(-2);
     const day = ('0' + date.getDate()).slice(-2);
     return `${year}-${month}-${day}`;
   }

  loadPaces() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.getActivePace, { headers: headers }).subscribe(
      (data: any) => {
        this.paces = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );
  }

  ridetypes() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.getActivePreferredType, { headers: headers }).subscribe(
      (data: any) => {
        this.rideTypes = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );
  }

  loadstates() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.getActiveStates, { headers: headers }).subscribe(
      (data: any) => {
        this.states = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );
  }


  loadCity() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.getActiveCity, { headers: headers }).subscribe(
      (data: any) => {
        this.cities = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );
  }


  loadPurpose() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.purpose, { headers: headers }).subscribe(
      (data: any) => {
        this.purposes = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );
  }

  loadlevels() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.levels, { headers: headers }).subscribe(
      (data: any) => {
        this.levels = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  loadOutinDoor() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.recurrence, { headers: headers }).subscribe(
      (data: any) => {
        this.inoutdoors = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );
  }



  loadMygroups() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.getGroupsByUser, { headers: headers }).subscribe(
      (data: any) => {
        this.groups = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }

  onNoClick(): void {
    this.dialogRef.close();
  }



 

  onSave(): void {
    // Handle save action
    this.dialogRef.close(this.createRideForm.value);
  }
}


