import { Component,OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NgIf } from '@angular/common';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { RideEditComponent } from '../../../../app/forms/create-event-types/ride-edit/ride-edit.component';
import { RiderescheduleComponent } from '../../../../app/forms/create-event-types/ridereschedule/ridereschedule.component';
import { RidereditrouteComponent } from '../../../../app/forms/create-event-types/ridereditroute/ridereditroute.component';
import { ViewrouteComponent } from '../viewroute/viewroute.component';
import { UpcomingridesviewdetailsComponent } from '../upcomingridesviewdetails/upcomingridesviewdetails.component';





@Component({
  selector: 'app-upcomingrides',
  standalone: true,
  imports: [MatCardModule, MatIconModule, MatSidenavModule, HttpClientModule, NgIf, MatExpansionModule, CommonModule, MatMenuModule, MatButtonModule, RouterLink, MatTableModule, MatPaginatorModule],
  templateUrl: './upcomingrides.component.html',
  styleUrl: './upcomingrides.component.scss',
  providers: [DatePipe]

})
export class UpcomingridesComponent implements OnInit {
  groupdID: string | null = null;

  // displayedColumns: string[] = ['date','timelocation', 'name', 'outindoor', 'groupride'];
  // dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  displayedColumns: string[] = ['name'];
  dataSource = new MatTableDataSource<any>(); 
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('sidenav') sidenav!: MatSidenav;

  ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
  }
  toggleSidenav() {

    this.sidenav.toggle();
}

constructor(private http: HttpClient, private datePipe: DatePipe, public dialog: MatDialog) { }

ngOnInit(): void {
  this.fetchRidesData();
}


openEditDialog(element: any): void {
  const dialogRef = this.dialog.open(RideEditComponent, {
    width: '34%',
    data: { ...element }


  });
  console.log('element Dialog result:', element);

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the result from the dialog (e.g., update the record)
      console.log('Dialog result:', result);
    }
  });
}






openRescheduleDialog(element: any): void {
  const dialogRef = this.dialog.open(RiderescheduleComponent, {
    width: '34%',
    data: { ...element }


  });
  console.log('element Dialog result:', element);

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the result from the dialog (e.g., update the record)
      console.log('Dialog result:', result);
    }
  });
}



openUpcomingridesviewdetailsComponentDialog(element: any): void {
  const dialogRef = this.dialog.open(UpcomingridesviewdetailsComponent, {
    data: { ...element },
    restoreFocus: false, 
    width: "45%",
    height: "100vh",
    position: { right: '0' },

  });
  console.log('element Dialog result:', element);

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the result from the dialog (e.g., update the record)
      console.log('Dialog result:', result);
    }
  });
}




openViewRouteDialog(element: any): void {
  const dialogRef = this.dialog.open(ViewrouteComponent, {
    width: '34%',
    data: { ...element }


  });
  console.log('element Dialog result:', element);

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the result from the dialog (e.g., update the record)
      console.log('Dialog result:', result);
    }
  });
}


openEditRouteDialog(element: any): void {
  const dialogRef = this.dialog.open(RidereditrouteComponent, {
    width: '34%',
    data: { ...element }


  });
  console.log('element Dialog result:', element);

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the result from the dialog (e.g., update the record)
      console.log('Dialog result:', result);
    }
  });
}


fetchRidesData() {
  this.groupdID = localStorage.getItem('selectedGroupId');

  // Make HTTP GET request to fetch rides data
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  this.http.get<any>(BASE_URL + ApiEndpoints.ridesUpComingPerGroup+this.groupdID, { headers: headers }).subscribe(
    (data) => {
      console.log('Error fetching rides data:', data);

      this.dataSource.data = data; // Assign fetched data to the data source
      this.dataSource.paginator = this.paginator; // Assign paginator after fetching data
    },
    (error) => {
      console.error('Error fetching rides data:', error);
    }
  );
}

formatDate(dateString: string): string {
  const date = new Date(dateString);
  return this.datePipe.transform(date, 'EEE d') || '';
}

formatTimeTo12Hour(time: string): string {
  if (!time) return '';

  const [hourString, minuteString] = time.split(':');
  let hour = parseInt(hourString, 10);
  const minute = parseInt(minuteString, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';

  hour = hour % 12;
  hour = hour ? hour : 12; // the hour '0' should be '12'

  const minuteFormatted = minute < 10 ? '0' + minute : minute;
  return `${hour}:${minuteFormatted} ${ampm}`;
}


cancelRide(element: any) {
  // Implement cancellation logic here
  console.log('Cancel ride for:', element);
  // Example: Remove the element from dataSource.data array
  // const index = this.dataSource.data.indexOf(element);
  // if (index >= 0) {
  //     this.dataSource.data.splice(index, 1);
  // }


  
const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

this.http.post(BASE_URL + ApiEndpoints.cancelRide+element.rideId, '', { headers: headers }).subscribe(
  (res: any) => {
    if (res) {
      alert("Ride cancelled successfully");
      // this.router.navigate(['/dashboard']);
      window.location.reload();

    } else {
      // Handle other cases where response is not as expected
      console.error("Unexpected response:", res);
      alert("Error, Failed");
    }
  },
  (error) => {
    if (error.status === 401) {
      // Handle 401 Unauthorized error
      console.log("Unauthorized:", error.status);
      alert("Unauthorized");
    } else if (error.status === 500) {
      // Handle 401 Unauthorized error
      console.log("Unauthorized:", error.status);
      alert("Internal server error");
    } else {
      // Handle other errors
      console.error("Error logging in:", error);
      alert("Failed creating record. Please try again later.");
    }
  }
);
}
}



