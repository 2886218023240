<mat-card class="trinta-card recent-orders-card mb-25 bg-white border-none d-block">
  <mat-card-content>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let element of dataSource.data">
            <mat-expansion-panel-header>
                <div class="header-content">
                    <mat-panel-title>
                        <div class="date-container">
                            <div class="day">{{ formatDate(element.date) }}</div>

                        </div>
                        <div class="timelocation-container">
                            <div class="time">{{ formatTimeTo12Hour(element.time) }}</div>
                            <div class="location">{{ element.startlocation }}</div>
                        </div>

                        <div class="name-container">
                            <div>{{ element.name }}</div>
                        </div>
                        <div class="outindoor-container">
                            <div class="type">{{ element.pace }}</div>
                            <div class="pace">{{ element.distance }}</div>
                        </div>
                        <!-- <button class="viewroutebtn"><a>View Route</a></button> -->
                        <!-- <mat-card-content>
                            <button mat-stroked-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="basicMenu">
                                Edit <span class="editicon"><mat-icon class="editicon">keyboard_arrow_down</mat-icon>
                                </span>
                            </button>
                            <mat-menu #basicMenu="matMenu">
                                <button mat-menu-item>Reschedule</button>
                            </mat-menu>
                        </mat-card-content> -->

                        <span mat-stroked-button (click)="$event.stopPropagation()" class="viewdetails" (click)="toggleSidenav()">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                                <path d="m280-240-56-56 184-184-184-184 56-56 240 240-240 240Zm360 0v-480h80v480h-80Z"/>
                            </svg>
                        </span>


                    </mat-panel-title>
                </div>
            </mat-expansion-panel-header>
            <div class="mat-expansion-panel-content">
                <div class="content-details">
                    <div class="row viewsection">

                        <div class="row viewsection1">


                    <div class="row expcoln">

                        <div class="row fcol">
                            <div class="textlabel">Start Location:   {{ element.startlocation }}</div>

                        </div>
                        <div class="row scol">
                            <div class="textlabel">Riding Type        :   {{element.preferredBikeTypes}}</div>
                        </div>
                    </div>

                    <div class="row expcoln">
                        <div class="row scol">
                            <div class="textlabel">Pace                  :   {{ element.pace }}</div>
                        </div>
                        <div class="row scol">
                            <div class="textlabel">Time                  :   {{ formatTimeTo12Hour(element.time) }}</div>
                        </div>
                    </div>

                    <div class="row expcoln">

                        <div class="row fcol">
                            <div class="textlabel">Ride Leader         :   {{ element.rideLeader }}</div>

                        </div>
                        <div class="row scol">
                            <div class="textlabel">Purpose:           :   {{ element.purpose }}</div>
                        </div>
                    </div>


                    <div class="row expcoln">

                        <div class="row fcol">
                            <div class="textlabel">Privacy            :   {{ element.privacy }}</div>
                        </div>
                        <div class="row scol">
                            <div class="textlabel">Level                :   {{ element.level }}</div>

                        </div>

                    </div>

                    <div class="row">
                        <div class="textlabel">Description            :   {{ element.description }}</div>
                    </div>
                    </div>

                    <div class="row viewsection2">
                        <button class="viewroutebtn viewroutebtnexp" style="width: 70%;"><a href="">View Route</a></button> 
                        <div class="row">
                           
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
  </mat-card-content>
</mat-card>


