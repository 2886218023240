<mat-card
    class="trinta-card recent-orders-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Members
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <!-- <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu> -->
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="recent-orders-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource.data">

                    <!-- user Column -->
                    <ng-container matColumnDef="img">
                        <th mat-header-cell *matHeaderCellDef>
                            Profile
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="product-info d-flex align-items-center">
                                <a routerLink="/" class="image d-block">
                                    <img src="../../../../assets/pr1.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="d-block fw-semibold">
                                        {{element.name}}
                                    </a>
                                  
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Customer Column -->
                    <!-- <ng-container matColumnDef="longestride">
                        <th mat-header-cell *matHeaderCellDef>
                            Longest Ride
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.name}}
                        </td>
                    </ng-container> -->

                   

                    <!-- Quantity Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.name}}
                        </td>
                    </ng-container>

                    <!-- groupride Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>
                            Emails
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.email}}
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <!-- <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="text-end pr-0">
                            Membership
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-end pr-0">
                            <span class="trinta-badge fw-medium pending d-inline-block" *ngIf="element.status.pending">
                                {{element.status.pending}}
                            </span>
                            <span class="trinta-badge fw-medium delivered d-inline-block" *ngIf="element.status.delivered">
                                {{element.status.delivered}}
                            </span>
                        </td>
                    </ng-container> -->

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>