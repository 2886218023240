<mat-card
    class="trinta-card notifications-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Notifications
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul class="m-0 p-0 list-unstyled">
            <li class="d-md-flex">
                <img src="assets/images/users/user25.jpg" class="rounded-circle" alt="user-image">
                <div>
                    <h6>
                        Shane Watson
                    </h6>
                    <p class="text-black position-relative">
                        <i class="ri-message-2-line"></i>
                        Mentioned you in a comment "Well done! Proud of you ❤️ "
                    </p>
                    <span class="time text-black position-relative d-block">
                        <i class="ri-time-line"></i>
                        10:20 AM <span class="text-body">August 7, 2024</span>
                    </span>
                </div>
            </li>
            <li class="d-md-flex">
                <img src="assets/images/users/user1.jpg" class="rounded-circle" alt="user-image">
                <div>
                    <h6>
                        Duran Clayton
                    </h6>
                    <p class="text-black position-relative">
                        <i class="ri-calendar-2-line"></i>
                        Created an event Rome holidays
                    </p>
                    <span class="time text-black position-relative d-block">
                        <i class="ri-time-line"></i>
                        10:11 AM <span class="text-body">August 6, 2024</span>
                    </span>
                </div>
            </li>
            <li class="d-md-flex">
                <img src="assets/images/users/user5.jpg" class="rounded-circle" alt="user-image">
                <div>
                    <h6>
                        Black Smith
                    </h6>
                    <p class="text-black position-relative">
                        <i class="ri-thumb-up-line"></i>
                        Liked your comment "Amazing Works️"
                    </p>
                    <span class="time text-black position-relative d-block">
                        <i class="ri-time-line"></i>
                        12:12 AM <span class="text-body">August 5, 2024</span>
                    </span>
                </div>
            </li>
            <li class="d-md-flex">
                <img src="assets/images/users/user3.jpg" class="rounded-circle" alt="user-image">
                <div>
                    <h6>
                        Alexis Clarke
                    </h6>
                    <p class="text-black position-relative">
                        <i class="ri-message-2-line"></i>
                        Mentioned you in a comment "This is too good to be true!"
                    </p>
                    <span class="time text-black position-relative d-block">
                        <i class="ri-time-line"></i>
                        08:40 AM <span class="text-body">August 4, 2024</span>
                    </span>
                </div>
            </li>
            <li class="d-md-flex">
                <img src="assets/images/users/user4.jpg" class="rounded-circle" alt="user-image">
                <div>
                    <h6>
                        David Warner
                    </h6>
                    <p class="text-black position-relative">
                        <i class="ri-thumb-up-line"></i>
                        Liked your comment "Welcome to the team️"
                    </p>
                    <span class="time text-black position-relative d-block">
                        <i class="ri-time-line"></i>
                        11:23 PM <span class="text-body">August 3, 2024</span>
                    </span>
                </div>
            </li>
        </ul>
    </mat-card-content>
</mat-card>