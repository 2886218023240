<div class="title text-center">
  <h2 mat-dialog-title class="fw-semibold">Create Event</h2>
  <button mat-mini-fab color="primary" (click)="onNoClick()" class="cancel">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>


  
      
       








  <form  [formGroup]="createEventForm" (ngSubmit)="onSubmit()" class="d-flex flex-column">

    <mat-label>Event Name</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput type="text" formControlName="title" id="title" name="title">
    </mat-form-field>

    
    <mat-label>Event Type</mat-label>
      <mat-form-field class="custom-form-field" >
        <mat-select formControlName="la_Event_Type_ID" id="la_Event_Type_ID" name="la_Event_Type_ID">
          <mat-option value="" disabled>Select Type</mat-option>
          <mat-option *ngFor="let eventType of eventTypes" [value]="eventType.id">{{ eventType.name }}</mat-option>
        </mat-select>
      </mat-form-field>

   

    <mat-label>Description</mat-label>
    <mat-form-field class="textarea custom-form-field">
      <textarea matInput rows="4" formControlName="description" ></textarea>
    </mat-form-field>


    <mat-label>Location</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput type="text" formControlName="location" id="location" name="location">
    </mat-form-field>


    <mat-label>Cost</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput type="number" formControlName="amount"  id="amount" name="amount">
    </mat-form-field>


    <mat-label>Organizer</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput type="text" formControlName="organizer"  id="organizer" name="organizer">
    </mat-form-field>
 
    
    <mat-label>Time</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput type="time" formControlName="time"  id="time" name="time">
    </mat-form-field>

    
    <mat-label>Group Size</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput type="number" formControlName="groupSize"  id="groupSize" name="groupSize">
    </mat-form-field>


    <mat-label>Address</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput type="text" formControlName="address"  id="address" name="address">
    </mat-form-field>

    <mat-label>Start Date</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput [matDatepicker]="startPicker" formControlName="date" [value]="displayDate" (dateChange)="onDateChange($event)">
      <mat-datepicker-toggle matIconSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>
    
    <mat-label>End Date</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput [matDatepicker]="endPicker" formControlName="endDate" [value]="displayEndDate" (dateChange)="onDateChangeEnddate($event)">
      <mat-datepicker-toggle matIconSuffix [for]="endPicker"></mat-datepicker-toggle>
      <mat-datepicker #endPicker></mat-datepicker>
    </mat-form-field>
    
    <div class="row custom-form-field" style="padding-top: 12px; padding-bottom: 8px;">
      <div class="col-sm-6">
        <mat-label>Recurring</mat-label>
      </div>
      <div class="row col-sm-6">
        <mat-radio-group aria-label="Select an option" class="col-sm-6 d-flex justify-content-between flex-wrap" formControlName="isRecurrent" id="isRecurrent" name="isRecurrent">
          <mat-radio-button value="true">Yes</mat-radio-button>
          <mat-radio-button value="false">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>


    <mat-label>Link</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput formControlName="url" name="url" id="url">
      <mat-icon matSuffix>link</mat-icon>
    </mat-form-field>

    <mat-label>Image</mat-label>
    <div class="upload-button-container custom-form-field">
      <input type="file" (change)="onFileSelected($event, 'logo')" accept="image/*" placeholder="Logo">
      <mat-icon class="upload-icon">cloud_upload</mat-icon>
    </div>

    <div class = "d-flex button-container submitbtn"  style="padding-top: 16px; padding-bottom: 16px">
    <button mat-stroked-button color="primary" type="submit">Save Draft</button>
    <button mat-raised-button color="primary" type="submit">Post Event</button>

  </div>

  </form>
</div>
<!-- Basic Form -->
<div class="row" id="sign-in-form">
  <div class="col-md-6">
    <app-basic-form></app-basic-form>
  </div>
</div>
