import { Component,OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { HttpClientModule } from '@angular/common/http';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-upcomingrideshistory',
  standalone: true,
  imports: [MatCardModule, MatIconModule, MatSidenavModule, HttpClientModule, NgIf, MatExpansionModule, CommonModule, MatMenuModule, MatButtonModule, RouterLink, MatTableModule, MatPaginatorModule],
  templateUrl: './upcomingrideshistory.component.html',
  styleUrls: ['./upcomingrideshistory.component.scss'],
  providers: [DatePipe]

})
export class UpcomingrideshistoryComponent implements OnInit {
  groupdID: string | null = null;

  
  displayedColumns: string[] = ['name'];
  dataSource = new MatTableDataSource<any>(); 
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('sidenav') sidenav!: MatSidenav;

  ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
  }
  toggleSidenav() {

    this.sidenav.toggle();
}


  constructor(private http: HttpClient,  private datePipe: DatePipe) { }

    ngOnInit(): void {
      this.fetchRidesData();
    }



fetchRidesData() {
  this.groupdID = localStorage.getItem('selectedGroupId');

  // Make HTTP GET request to fetch rides data
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  this.http.get<any>(BASE_URL + ApiEndpoints.ridesHistoryPerGroup+this.groupdID, { headers: headers }).subscribe(
    (data) => {
      this.dataSource.data = data; // Assign fetched data to the data source
      this.dataSource.paginator = this.paginator; // Assign paginator after fetching data
    },
    (error) => {
      console.error('Error fetching rides data:', error);
    }
  );
}

formatDate(dateString: string): string {
  const date = new Date(dateString);
  return this.datePipe.transform(date, 'EEE d') || '';
}

formatTimeTo12Hour(time: string): string {
  if (!time) return '';

  const [hourString, minuteString] = time.split(':');
  let hour = parseInt(hourString, 10);
  const minute = parseInt(minuteString, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';

  hour = hour % 12;
  hour = hour ? hour : 12; // the hour '0' should be '12'

  const minuteFormatted = minute < 10 ? '0' + minute : minute;
  return `${hour}:${minuteFormatted} ${ampm}`;
}







  
}

