<mat-card-content class="row d-flex">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="membersectiontab col-xl-9 col-lg-9 col-sm-12">
    <mat-tab label="Upcoming">
      <div class="container membersectiontab">
        <app-upcomingrides/>
      </div>
    </mat-tab>

    <mat-tab label="History">
      <div class="container membersectiontab">
        <app-upcomingrideshistory/>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="col-xl-3 col-lg-3 col-sm-12">
    <app-calendar></app-calendar>
    <app-ride-route-list/>
  </div>

</mat-card-content>
