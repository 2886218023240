import {DomSanitizer} from "@angular/platform-browser";

declare let $: any;
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { ToggleService } from '../app/common/header/toggle.service';
import { SidebarComponent } from './common/sidebar/sidebar.component';
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { RouterOutlet, Router, NavigationCancel, NavigationEnd, RouterLink } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import {MatIconRegistry} from "@angular/material/icon";
import {LacorsaIcons} from "./lacorsaicons";


const BACK_ICON=
  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">\n' +
  '  <path fill="#1F2A44" d="m4 10-.707.707L2.586 10l.707-.707L4 10Zm17 8a1 1 0 1 1-2 0h2ZM8.293 15.707l-5-5 1.414-1.414 5 5-1.414 1.414Zm-5-6.414 5-5 1.414 1.414-5 5-1.414-1.414ZM4 9h10v2H4V9Zm17 7v2h-2v-2h2Zm-7-7a7 7 0 0 1 7 7h-2a5 5 0 0 0-5-5V9Z"/>\n' +
  '</svg>\n'
const CARRET_LEFT_OUTLINE = '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30">\n' +
  '  <rect width="29" height="29" x=".5" y=".5" stroke="#1F2A44" rx="2.5"/>\n' +
  '  <path stroke="#1F2A44" stroke-width="2" d="m18.75 7.5-7.5 7.5 7.5 7.5"/>\n' +
  '</svg>'
const CARRET_RIGHT_OUTLINE = '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30">\n' +
  '  <rect width="29" height="29" x=".5" y=".5" stroke="#1F2A44" rx="2.5"/>\n' +
  '  <path stroke="#1F2A44" stroke-width="2" d="m11.25 7.5 7.5 7.5-7.5 7.5"/>\n' +
  '</svg>\n'
const SHARE = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">\n' +
  '  <path fill="#1F2A44" d="M18.002 21.5a2.413 2.413 0 0 1-1.771-.73A2.404 2.404 0 0 1 15.5 19a2.55 2.55 0 0 1 .138-.815l-7.742-4.562a2.65 2.65 0 0 1-.854.645A2.387 2.387 0 0 1 6 14.5a2.41 2.41 0 0 1-1.77-.728 2.408 2.408 0 0 1-.73-1.77c0-.694.243-1.284.73-1.771A2.414 2.414 0 0 1 6 9.5c.37 0 .718.077 1.042.232.325.154.61.369.854.645l7.742-4.562A2.55 2.55 0 0 1 15.5 5c0-.695.243-1.285.729-1.77a2.405 2.405 0 0 1 1.769-.73c.694 0 1.284.243 1.771.729a2.41 2.41 0 0 1 .731 1.769c0 .693-.243 1.284-.73 1.771A2.404 2.404 0 0 1 18 7.5c-.37 0-.718-.077-1.042-.232a2.65 2.65 0 0 1-.854-.645l-7.742 4.562a2.535 2.535 0 0 1 .103 1.226 2.21 2.21 0 0 1-.103.404l7.742 4.562a2.65 2.65 0 0 1 .854-.645A2.387 2.387 0 0 1 18 16.5c.695 0 1.285.243 1.77.729.487.485.73 1.075.73 1.769s-.243 1.284-.728 1.771a2.41 2.41 0 0 1-1.77.731Z"/>\n' +
  '</svg>\n'
const SUNNY = '<svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" fill="none" viewBox="0 0 19 18">\n' +
  '  <path fill="#fff" fill-rule="evenodd" d="M8.938 2.813V1.125h1.124v1.688H8.938Zm3.169.547.843-1.46.975.562-.844 1.462-.974-.563ZM9.5 12.938a3.938 3.938 0 1 0 0-7.876 3.938 3.938 0 0 0 0 7.875Zm0 1.124a5.063 5.063 0 1 0 0-10.125 5.063 5.063 0 0 0 0 10.126Zm6.188-5.624h1.687v1.124h-1.688V8.438Zm.35-3.863-1.46.844.562.974 1.46-.843-.562-.975ZM5.076 2.461l.844 1.462.974-.563L6.05 1.9l-.975.562ZM3.86 6.393 2.4 5.55l.562-.975 1.462.844-.563.974Zm5.078 8.794v1.688h1.124v-1.688H8.938Zm-5.626-6.75H1.625v1.126h1.688V8.437ZM2.4 12.45l1.461-.843.563.974-1.462.844-.562-.975Zm3.52 1.627-.844 1.462.975.562.843-1.461-.974-.563Zm7.031 2.024-.843-1.461.974-.563.844 1.462-.975.562Zm1.627-3.52 1.462.844.562-.975-1.461-.843-.563.974Z" clip-rule="evenodd"/>\n' +
  '</svg>\n'

const WIND = '<svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" fill="none" viewBox="0 0 19 18">\n' +
  '  <path fill="#fff" fill-rule="evenodd" d="M8.375 6.184v-.003H5.191l3.054-1.104a.562.562 0 0 1 .203-.014.562.562 0 0 1 .408.256.563.563 0 0 1 .06.478.562.562 0 0 1-.332.35.554.554 0 0 1-.209.037ZM2.75 5.867v1.439h5.614a1.684 1.684 0 0 0 1.24-.524h-.001a1.686 1.686 0 0 0 .45-1.371 1.684 1.684 0 0 0-.777-1.216 1.682 1.682 0 0 0-.697-.246 1.683 1.683 0 0 0-.716.07l-.017.006-.002.001-5.094 1.84Zm0 4.82v1.155l7.428 2.643a1.969 1.969 0 1 0 .742-3.797v-.002H2.75Zm8.162 1.125H6.021l4.595 1.634v.001a.842.842 0 1 0 .297-1.633h-.001v-.002Zm2.536-5.485-.554-.98v-.001a2.25 2.25 0 0 1 2.886 3.336 2.25 2.25 0 0 1-1.783.875H2.75V8.432h11.248V8.43a1.124 1.124 0 1 0-.55-2.104Z" clip-rule="evenodd"/>\n' +
  '</svg>\n'

const RAIN = '<svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" fill="none" viewBox="0 0 19 18">\n' +
  '  <path fill="#fff" fill-rule="evenodd" d="M14 13.476c1.898-.218 3.375-1.887 3.375-3.914 0-2.174-1.7-3.937-3.797-3.937-.081 0-.162.003-.241.008-.706-2.601-3.01-4.508-5.745-4.508-3.295 0-5.967 2.77-5.967 6.188 0 2.892 1.914 5.32 4.5 5.999v-1.175C4.185 11.491 2.75 9.6 2.75 7.312c0-2.834 2.206-5.062 4.842-5.062 2.236 0 4.163 1.603 4.696 3.82h.002c.075.307.123.626.141.953.35-.176.74-.273 1.147-.273 1.438 0 2.672 1.22 2.672 2.812 0 1.434-1.001 2.566-2.25 2.777v1.137ZM8.095 15.75c.136 0 .281-.118.281-.307 0-.104-.035-.298-.145-.585a4.964 4.964 0 0 0-.152-.35c-.168.366-.266.694-.266.935 0 .189.146.307.282.307Zm-.68-2.387c.343-.578.679-.989.679-.989s.32.425.655 1c.367.63.752 1.44.752 2.069 0 .791-.629 1.432-1.406 1.432-.776 0-1.406-.641-1.407-1.432 0-.69.367-1.477.726-2.08Zm4.336.393c0 .188-.145.307-.281.307a.296.296 0 0 1-.282-.307c0-.241.098-.57.266-.935.057.118.108.236.152.35.11.287.145.481.145.585Zm-.282-3.07s-.336.411-.68.989c-.359.604-.726 1.39-.726 2.08 0 .791.63 1.433 1.407 1.433s1.406-.642 1.406-1.432c0-.63-.385-1.44-.752-2.07-.335-.575-.655-1-.655-1Z" clip-rule="evenodd"/>\n' +
  '</svg>\n'

const TIME_FILL = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">\n' +
  '  <path fill="#1F2A44" fill-rule="evenodd" d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm1-14.5a1 1 0 1 0-2 0v5.25c0 .69.56 1.25 1.25 1.25h3.25a1 1 0 1 0 0-2H13V6.5Z" clip-rule="evenodd"/>\n' +
  '</svg>\n'
const PIN_FILL = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">\n' +
  '  <path fill="#1F2A44" fill-rule="evenodd" d="M12.398 19.804C13.881 19.034 19 16.016 19 11a7 7 0 1 0-14 0c0 5.016 5.119 8.035 6.602 8.804a.855.855 0 0 0 .796 0ZM12 14a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clip-rule="evenodd"/>\n' +
  '</svg>\n'

const SPEEDOMETER = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">\n' +
  '  <path stroke="#fff" stroke-linejoin="round" d="M3.5 14a8.5 8.5 0 0 1 17 0"/>\n' +
  '  <path stroke="#fff" stroke-linejoin="round" d="M9.5 15a2.5 2.5 0 1 1 5 0"/>\n' +
  '  <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" d="m13.5 12.5 2-3m5 4.5v.5a1 1 0 0 1-1 1h-15a1 1 0 0 1-1-1V14"/>\n' +
  '</svg>\n'
const CLOCK = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">\n' +
  '  <circle cx="12" cy="12" r="8.5" stroke="#fff"/>\n' +
  '  <path stroke="#fff" stroke-linecap="round" d="M5 2.804A6 6 0 0 0 2.804 5M19 2.804A6 6 0 0 1 21.196 5M12 6.5v5.25c0 .138.112.25.25.25h4.25"/>\n' +
  '</svg>\n'
const TIME_OUTLINE = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">\n' +
  '  <circle cx="12" cy="12" r="8.5" stroke="#fff"/>\n' +
  '  <path stroke="#fff" stroke-linecap="round" d="M16.5 12h-4.25a.25.25 0 0 1-.25-.25V8.5"/>\n' +
  '</svg>\n'



  @Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet,ReactiveFormsModule, CommonModule, RouterLink, SidebarComponent, HeaderComponent, FooterComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})


export class AppComponent {

    title = 'Lacorsa';

    routerSubscription: any;
    location: any;

    constructor(
        public router: Router,
        public toggleService: ToggleService,
        @Inject(PLATFORM_ID) private platformId: Object,
        iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    ) {
        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
      iconRegistry.addSvgIconLiteral('back', sanitizer.bypassSecurityTrustHtml(BACK_ICON));
      iconRegistry.addSvgIconLiteral('carret_left_outline', sanitizer.bypassSecurityTrustHtml(CARRET_LEFT_OUTLINE));
      iconRegistry.addSvgIconLiteral('carret_right_outline', sanitizer.bypassSecurityTrustHtml(CARRET_RIGHT_OUTLINE));
      iconRegistry.addSvgIconLiteral('share', sanitizer.bypassSecurityTrustHtml(SHARE));
      iconRegistry.addSvgIconLiteral('sunny', sanitizer.bypassSecurityTrustHtml(SUNNY));
      iconRegistry.addSvgIconLiteral('wind', sanitizer.bypassSecurityTrustHtml(WIND));
      iconRegistry.addSvgIconLiteral('rain', sanitizer.bypassSecurityTrustHtml(RAIN));
      iconRegistry.addSvgIconLiteral('time_fill', sanitizer.bypassSecurityTrustHtml(TIME_FILL));
      iconRegistry.addSvgIconLiteral('pin_fill', sanitizer.bypassSecurityTrustHtml(PIN_FILL));
      iconRegistry.addSvgIconLiteral('clock', sanitizer.bypassSecurityTrustHtml(CLOCK));
      iconRegistry.addSvgIconLiteral('speedometer', sanitizer.bypassSecurityTrustHtml(SPEEDOMETER));
      iconRegistry.addSvgIconLiteral('time_outline', sanitizer.bypassSecurityTrustHtml(TIME_OUTLINE));
    }

    // Toggle Service
    isToggled = false;

    // Dark Mode
    toggleTheme() {
        this.toggleService.toggleTheme();
    }

    // Settings Button Toggle
    toggle() {
        this.toggleService.toggle();
    }

    // ngOnInit
    ngOnInit(){
        if (isPlatformBrowser(this.platformId)) {
            this.recallJsFuntions();
        }
    }

    // recallJsFuntions
    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            this.scrollToTop();
        });
    }
    scrollToTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }




}
