<div class="title text-center">
  <h2 mat-dialog-title class="fw-semibold">Edit Ride</h2>
  <button mat-mini-fab color="primary" (click)="onNoClick()" class="cancel">
    <mat-icon>
      close
    </mat-icon>
  </button>
</div>


<div mat-dialog-content>
  <form [formGroup]="createRideForm" (ngSubmit)="onSubmit()" class="d-flex form-group flex-column">



    <mat-label>Riding Group</mat-label>
    <mat-form-field class="custom-form-field">
      <mat-select formControlName="ridinggroupId" id="ridinggroupId" name="ridinggroupId">
        <mat-option *ngFor="let group of groups" [value]="group.la_RidingGroup_ID">{{ group.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-label>Ride Name</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput formControlName="rideName" id="rideName" name="rideName">
    </mat-form-field>


    <mat-label>Description</mat-label>
    <mat-form-field class="custom-form-field">
      <textarea matInput formControlName="description" id="description" name="description" rows="4"
      ></textarea>
        </mat-form-field>


    <div class="row custom-form-field" style="padding-top: 12px; padding-bottom: 12px;">
      <div class="col-sm-6">
        <mat-radio-group
          aria-label="Select an option"
          formControlName="outDoor"
          id="outDoor"
          name="outDoor"
          (change)="onRestrictionChange('outDoor', $event)">
          <mat-radio-button [value]="true">Outdoor ride</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="row col-sm-6">
        <mat-radio-group
          aria-label="Select an option"
          formControlName="inDoor"
          id="inDoor"
          name="inDoor">
          <mat-radio-button [value]="true">Indoor Ride</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>


    <div class="row" class="custom-form-field" style="padding-top: 12px;">
      <mat-form-field class="col-sm-6" style="padding-right: 4px;">
        <!-- <mat-label>Start Date</mat-label> -->
        <input matInput [matDatepicker]="picker" formControlName="date" id="date" [value]="displayDate"
          (dateChange)="onDateChange($event)">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>



      <mat-form-field class="col-sm-6" style="padding-left: 4px;">
        <!-- <mat-label>Start Time</mat-label> -->
        <input matInput formControlName="time" type="time" id="time" name="time" rows="5">
      </mat-form-field>
    </div>

    <mat-label>End Time</mat-label>
    <div class="row custom-form-field">
      <mat-form-field class="col-sm-6">
        <input matInput formControlName="endTime" type="time" id="endTime" name="endTime">
      </mat-form-field>
    </div>



    <mat-label>Starting Location</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput type="text" formControlName="startLocation" id="startLocation" name="startLocation">
    </mat-form-field>

    <div class="row custom-form-field" style="padding-top: 16px;">
      <mat-form-field class="col-sm-6">
        <!-- <mat-label>State</mat-label> -->
        <mat-select formControlName="stateId" id="stateId" name="stateId">
          <mat-option *ngFor="let state of states" [value]="state.id">{{ state.name }}</mat-option>
        </mat-select>
        <!-- <input matInput type="text" formControlName="stateId" id="stateId" name="stateId"> -->
      </mat-form-field>
      <mat-form-field class="col-sm-6">
        <!-- <mat-label>City</mat-label> -->
        <mat-select formControlName="cityId" id="cityId" name="cityId">
          <mat-option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</mat-option>
        </mat-select>
        <!-- <input matInput type="text" formControlName="cityId" id="cityId" name="cityId"> -->
      </mat-form-field>
    </div>


    <mat-label>Route Name</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput type="text" formControlName="route" id="route" name="route">
    </mat-form-field>

    <mat-label>Route Link</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput type="text" formControlName="link" id="link" name="link">
      <mat-icon matSuffix>link</mat-icon>
    </mat-form-field>

    <mat-label>Level</mat-label>
    <mat-form-field class="custom-form-field">
      <mat-select formControlName="levelId" id="levelId" name="levelId">
        <mat-option *ngFor="let level of levels" [value]="level.id">{{ level.name }}</mat-option>
      </mat-select>
    </mat-form-field>


    <div class="row" style="padding-top: 16px; padding-bottom: 25px;">
      <div class="col-sm-6">
        <mat-label>Is this a private ride ?</mat-label>
      </div>
      <div class="row col-sm-6">
        <mat-radio-group aria-label="Select an option" class="col-sm-6 d-flex justify-content-between flex-wrap"
          formControlName="privacyId" id="privacyId" name="privacyId">
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <mat-label>Participant Limit</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput formControlName="limit" id="limit" name="limit">
    </mat-form-field>


    <mat-label>Ride Type</mat-label>
    <mat-form-field class="custom-form-field">
      <mat-select formControlName="preferredBikeTypesId" id="preferredBikeTypesId" name="preferredBikeTypesId">
        <mat-option *ngFor="let rideType of rideTypes" [value]="rideType.id">{{ rideType.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-label>Distance</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput type="number" formControlName="distance" id="distance" name="distance">
    </mat-form-field>

    <div class="row custom-form-field" style="padding-top: 16px;">
      <mat-form-field class="col-sm-6">
        <!-- <mat-label>Pace</mat-label> -->
        <mat-select formControlName="paceId" id="paceId" name="paceId">
          <mat-option *ngFor="let pace of paces" [value]="pace.id">{{ pace.name }}</mat-option>

        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-sm-6">
        <!-- <mat-label>Purpose</mat-label> -->
        <mat-select formControlName="purposeId" id="purposeId" name="purposeId">
          <mat-option *ngFor="let purpose of purposes" [value]="purpose.id">{{ purpose.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <mat-label>Ride Leader</mat-label>
    <mat-form-field class="d-flex justify-content-end custom-form-field">
      <input matInput formControlName="rideLeader" name="rideLeader" id="rideLeader">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

    <mat-label>Cover Photo</mat-label>
    <div class="upload-button-container">
      <input type="file" (change)="onFileChange($event)" />
      <mat-icon class="upload-icon">cloud_upload</mat-icon>
    </div>

    <mat-form-field class="d-flex justify-content-end custom-form-field" style="display: none !important;">
      <input matInput formControlName="rideId" name="rideId" id="rideId">
    </mat-form-field>
    <div class="button-container submitbtn">
      <button mat-raised-button color="primary" type="submit" class="col-sm-5">Update Ride</button>
    </div>

  </form>
</div>