import { Component, ViewChild, Type, ViewEncapsulation, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterLink } from '@angular/router';
import { FeathericonsModule } from '../../../icons/feathericons/feathericons.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgFor } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { EditgroupdetailsComponent } from "../../../forms/create-event-types/editgroupdetails/editgroupdetails.component";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { Instagram } from 'angular-feather/icons';


@Component({
  selector: 'app-groupdetails',
  standalone: true,
  imports: [
    RouterLink,
    HttpClientModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    FeathericonsModule,
    CarouselModule,
    NgFor,
    MatProgressBarModule,
    MatMenuModule,
    MatMenuTrigger
  ],
  templateUrl: './groupdetails.component.html',
  styleUrls: ['./groupdetails.component.scss']
})
export class GroupdetailsComponent implements OnInit {
  description: string = '';
  name: string = '';
  invitedRidersOnly: boolean = false;
  adminApproval: boolean = false;
  socialLinks: string = '';
  locationComment: string = '';
  amount: string = '';
  stateId: string = '';
  cityId: string = '';
  logo: string = '';
  image: string = '';
  la_TypicalPace_ID: string = '';
  la_RidingGroup_ID: string = '';
  url: string = '';
  la_RidingLevel_ID: string = '';
  la_AnnualMileage_ID: string = '';
  la_RidingTypes: string = '';
  la_RidingType: string = '';
  la_RidingType_ID: string = '';
  invites: string = '';
  invitesvalue: boolean = false;
  allowMemberPost: boolean = false;
  x: string = '';
  instagram: string = '';
  faceBook: string = '';
  groupAdmin: string = '';

  profilePhoto: string = '';


  @ViewChild('menuTrigger', { static: true }) menuTrigger: MatMenuTrigger;

  // constructor(public dialog: MatDialog) {}


  productImages = [
    { url: 'assets/images/products/product-details1.jpg' },
    { url: 'assets/images/products/product-details2.jpg' },
    { url: 'assets/images/products/product-details3.jpg' },
    { url: 'assets/images/products/product-details4.jpg' }
  ];
  selectedImage: string = this.productImages[0].url;

  constructor(private http: HttpClient, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.getUserProfile();

    this.fetchgroupdetails();
  }

  changeImage(image: string): void {
    this.selectedImage = image;
  }

  fetchgroupdetails(): void {


    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    const groupdId = localStorage.getItem('selectedGroupId');

    if(groupdId == null){
      console.log("res.tokenres.token", localStorage.getItem('angular17token'));
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
      this.http.get(BASE_URL + ApiEndpoints.getGroupsByUser, { headers: headers }).subscribe(
        (data: any) => {
          console.log("group data", data);

          localStorage.setItem('selectedGroupId', data[0].la_RidingGroup_ID);
          this.fetchgroupdetails();
        },
        error => {
          console.log('Error fetching data:', error);
        }
      );
    }else{
    this.http.get<any[]>(`${BASE_URL}${ApiEndpoints.getGroupsByID}${groupdId}`, { headers })
      .subscribe(
        (data) => {
          console.log("group data", data);

          if (data && data.length > 0) {
            this.description = data[0].description;
            this.name = data[0].name;


            if(data[0].invitedRidersOnly == true){
              this.invites = "Invites Only";
              this.invitesvalue = true;

            }else{
              this.invites = "Open";
              this.invitesvalue = false;
            }

            if(data[0].allowMemberPost == true){
              // this.allowMemberPost = true;

            }else{
              // this.invites = "Open";
              this.allowMemberPost = false;
            }



            

            console.log("data[0].image", data[0].image)


            this.logo = `data:image/png;base64,${data[0].logo}`;
            this.image = `data:image/png;base64,${data[0].image}`;

            this.invitedRidersOnly = this.invitesvalue;
            this.socialLinks = data[0].socialLinks;
            this.locationComment = data[0].locationComment;
            this.la_RidingType = data[0].la_RidingType;
            this.adminApproval = data[0].adminApproval;
            this.amount = data[0].amount;
            // this.logo = data[0].logo;
            this.stateId = data[0].stateId;
            this.cityId = data[0].cityId;
            this.la_RidingGroup_ID = data[0].la_RidingGroup_ID;
            this.la_TypicalPace_ID = data[0].la_TypicalPace_ID;
            this.url = data[0].url;
            this.la_RidingLevel_ID = data[0].la_RidingLevel_ID;
            this.la_AnnualMileage_ID = data[0].la_AnnualMileage_ID;
            this.la_RidingType_ID = data[0].la_RidingType_ID;
            // this.allowMemberPost = data[0].allowMemberPost;
            this.x = data[0].x;
            this.instagram = data[0].instagram;
            this.faceBook = data[0].faceBook;
            this.groupAdmin = data[0].groupAdmin;


            this.profilePhoto = this.profilePhoto


            
            this.la_RidingGroup_ID =  data[0].la_RidingGroup_ID;
          }
          console.log("group data", data);
          
        },
        (error) => {
          console.error('Error fetching group details:', error);
        }
      );
  }

}


getUserProfile() {
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  this.http.get(BASE_URL + ApiEndpoints.getUserProfile, { headers: headers }).subscribe(
    (data: any) => {
      
      console.log("user profile", data.profilePhoto)
      
      // this.profilePhoto = data.profilePhoto;
      
      // Assuming data is an array of objects with properties 'value' and 'label'
    },
    error => {
      console.log('Error fetching purposes:', error);
    }
  );
}

openDialog(componentType: Type<any>) {
  const dialogRef = this.dialog.open(componentType, { 
    restoreFocus: false, 
    width: "45%",
    height: "100vh",
    position: { right: '0' },
    data: { ...this }
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the edited data here
      this.name = result.name;
      this.description = result.description;
      this.invitedRidersOnly = result.invitedRidersOnly;
      this.socialLinks = result.socialLinks;
      this.locationComment = result.locationComment;
      this.amount = result.amount;
      this.la_RidingGroup_ID = result.la_RidingGroup_ID;
      this.la_RidingLevel_ID = result.la_RidingLevel_ID;
      this.la_AnnualMileage_ID = result.la_AnnualMileage_ID;
      this.la_RidingType_ID = result.la_RidingType_ID;
      this.adminApproval = result.adminApproval;
      this.stateId = result.stateId;
      this.cityId = result.cityId;
      this.la_TypicalPace_ID = result.la_TypicalPace_ID
      this.url = result.url;
      this.allowMemberPost = result.allowMemberPost;
      this.x = result.x;
      this.instagram = result.instagram;
      this.faceBook = result.faceBook;
      this.logo = `data:image/png;base64,${result.logo}`;
      this.image = `data:image/png;base64,${result.image}`;
      this.profilePhoto = `data:image/png;base64,${result.profilePhoto}`;
      console.log('Edited data:', result);
    }
    this.menuTrigger.focus();
  });

   dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Handle the edited data here
        this.name = result.name;
        this.description = result.description;
        this.invitedRidersOnly = result.invitedRidersOnly;
        this.adminApproval = result.adminApproval;
        this.stateId = result.stateId;
        this.cityId = result.cityId;
        this.socialLinks = result.socialLinks;
        this.locationComment = result.locationComment;
        this.amount = result.amount;
        this.la_TypicalPace_ID = result.la_TypicalPace_ID
        this.url = result.url;
        this.la_RidingGroup_ID =  result.la_RidingGroup_ID;
        this.la_RidingLevel_ID = result.la_RidingLevel_ID;
        this.la_AnnualMileage_ID = result.la_AnnualMileage_ID;
        this.la_RidingType_ID = result.la_RidingType_ID;
        this.allowMemberPost = result.allowMemberPost;
        this.x = result.x;
        this.instagram = result.instagram;
        this.faceBook = result.faceBook;
        this.logo = `data:image/png;base64,${result.logo}`;
        this.image = `data:image/png;base64,${result.image}`;
        this.profilePhoto = `data:image/png;base64,${result.profilePhoto}`;

        console.log('Edited data:', result);
      }
      this.menuTrigger.focus();
    });
  
}

protected readonly EditgroupdetailsComponent = EditgroupdetailsComponent;

}
