import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NgIf } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';

@Component({
  selector: 'app-members',
  standalone: true,
  imports: [MatCardModule,NgIf, MatMenuModule, MatButtonModule, RouterLink, MatTableModule, MatPaginatorModule],

  templateUrl: './members.component.html',
  styleUrl: './members.component.scss'
})
export class MembersComponent {
    groupdID: string | null = null;

//   displayedColumns: string[] = ['user', 'longestride', 'allrides', 'groupride', 'status'];
//   dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

//   @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['img','name',  'email'];
  dataSource = new MatTableDataSource<any>(); 
  @ViewChild(MatPaginator) paginator: MatPaginator;



  ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
  }

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.fetmemberslist();
  }

  fetmemberslist() {

   this.groupdID = localStorage.getItem('selectedGroupId');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get<any>(BASE_URL + ApiEndpoints.getGroupMembers+ this.groupdID, { headers: headers }).subscribe(
      (data) => {
        this.dataSource.data = data; // Assign fetched data to the data source
        this.dataSource.paginator = this.paginator; // Assign paginator after fetching data
      },
      (error) => {
        console.error('Error fetching rides data:', error);
      }
    );
  }
}





export interface PeriodicElement {
  user: any;
  longestride: string;
  allrides: string;
  groupride: string;
  status: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    user: {
          img: 'assets/pr1.png',
          title: 'Comforta Armchair',
          date: 'Dec 16, 08:30 PM'
      },
      longestride: '508km',
      allrides: '32',
      groupride: '10',
      status: {
          pending: 'Elite',
          // delivered : 'Delivered'
      }
  },
  {
      user: {
          img: 'assets/pr2.png',
          title: 'Comforta Sofa EDM',
          date: 'Dec 15, 02:20 PM'
      },
      longestride: '400km',
      allrides: '26',
      groupride: '6',
      status: {
          // pending: 'Pending',
          delivered : 'Premiere'
      }
  },
  {
      user: {
          img: 'assets/pr3.png',
          title: 'Electric Bicycle',
          date: 'Dec 14, 10:00 AM'
      },
      longestride: '240km',
      allrides: '23',
      groupride: '7',
      status: {
          // pending: 'Pending',
          delivered : 'Club'
      }
  },
  {
      user: {
          img: 'assets/pr4.png',
          title: 'Sport Shoes',
          date: 'Dec 13, 03:43 AM'
      },
      longestride: '233km',
      allrides: '21',
      groupride: '8',
      status: {
          pending: 'Club',
          // delivered : 'Delivered'
      }
  },
  {
      user: {
          img: 'assets/pr1.png',
          title: 'Straw Hat',
          date: 'Dec 12, 12:09 PM'
      },
      longestride: '200km',
      allrides: '4',
      groupride: '3',
      status: {
          pending: 'Club',
      }
  },
 
];