<div class="d-flex align-items-center wrapper">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)="onTabChange($event)" class="parent-tab-group position-relative">
    <mat-tab class="tabsections mb-25">
      <ng-template mat-tab-label class="tablabel mb-25">
        <a routerLink="/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
          Dashboard
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <rect width="6" height="6" x="4" y="4" stroke="#1F2A44" stroke-linejoin="round" rx="1"/>
            <rect width="6" height="6" x="4" y="14" stroke="#1F2A44" stroke-linejoin="round" rx="1"/>
            <rect width="6" height="6" x="14" y="14" stroke="#1F2A44" stroke-linejoin="round" rx="1"/>
            <rect width="6" height="6" x="14" y="4" stroke="#1F2A44" stroke-linejoin="round" rx="1"/>
          </svg>

        </a>

<!--        <mat-icon>-->
<!--          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368">-->
<!--            <path d="M520-600v-240h320v240H520ZM120-440v-400h320v400H120Zm400 320v-400h320v400H520Zm-400 0v-240h320v240H120Zm80-400h160v-240H200v240Zm400 320h160v-240H600v240Zm0-480h160v-80H600v80ZM200-200h160v-80H200v80Zm160-320Zm240-160Zm0 240ZM360-280Z" />-->
<!--          </svg>-->
<!--        </mat-icon>-->
      </ng-template>

      <div class="row container mt-10">
        <div class="col-xxxl-9 col-xxl-10 col-md-12">
          <div class="row">
            <app-group-overview-stats></app-group-overview-stats>
          </div>
          <app-group-activity-charts></app-group-activity-charts>
          <div class="row">
            <div class="col-xxxl-9 col-xxl-10 col-md-9">
              <app-engagement-stats></app-engagement-stats>
              <app-payment-stats></app-payment-stats>
            </div>
            <div class="col-xxxl-3 col-xxl-2 col-md-3 pro-card-section">
              <app-go-pro-widget></app-go-pro-widget>
            </div>
          </div>
        </div>
        <div class="col-xxxl-3 col-xxl-2 col-md-12 mt-10">
          <app-ridcalendar></app-ridcalendar>
          <app-latestpost></app-latestpost>
        </div>
      </div>
    </mat-tab>

    <mat-tab>

      <ng-template mat-tab-label class="tablabel">
        <a routerLink="/dashboard/group-profile" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">

          Profile
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <circle cx="12" cy="8" r="2.5" stroke="#1F2A44" stroke-linecap="round"/>
            <path stroke="#1F2A44" d="M13.768 6.5a2 2 0 1 1 .732 2.732M10.232 6.5A2 2 0 1 0 9.5 9.232"/>
            <path stroke="#1F2A44" stroke-linecap="round" d="M12 12.5c4.08 0 5.134 3.302 5.405 5.007.087.545-.353.993-.905.993h-9c-.552 0-.992-.448-.905-.993C6.866 15.802 7.92 12.5 12 12.5Z"/>
            <path fill="#1F2A44" d="m19.296 15.416-.485.122.485-.122Zm-6.21-2.83-.368-.337-.62.68.907.15.082-.493Zm4.095 3.914-.48.139.104.361h.376v-.5ZM15.5 12c1.027 0 1.75.503 2.278 1.207.54.718.856 1.624 1.033 2.33l.97-.242c-.19-.764-.548-1.816-1.203-2.688C17.912 11.72 16.92 11 15.5 11v1Zm-2.044.923c.5-.548 1.155-.923 2.044-.923v-1c-1.217 0-2.127.53-2.782 1.249l.738.674Zm-.451.156c2.3.382 3.274 2.101 3.696 3.56l.96-.278c-.47-1.628-1.642-3.795-4.492-4.268l-.164.986ZM18.395 16H17.18v1h1.214v-1Zm.416-.462c.054.215-.104.462-.416.462v1c.87 0 1.62-.772 1.386-1.705l-.97.243Zm-7.898-2.952.082.493.908-.15-.62-.68-.37.337Zm-6.21 2.83.486.122-.485-.122ZM6.82 16.5v.5h.376l.104-.361-.48-.139ZM8.5 12c.89 0 1.543.375 2.044.923l.738-.674C10.627 11.53 9.717 11 8.5 11v1Zm-3.31 3.538c.177-.707.493-1.613 1.033-2.331C6.75 12.503 7.472 12 8.5 12v-1c-1.42 0-2.412.72-3.078 1.607-.655.872-1.012 1.925-1.203 2.688l.97.243Zm.416.462c-.312 0-.47-.247-.416-.462l-.97-.243C3.985 16.228 4.734 17 5.605 17v-1Zm1.214 0H5.605v1H6.82v-1Zm.48.639c.422-1.459 1.395-3.178 3.696-3.56l-.164-.986c-2.85.473-4.021 2.64-4.492 4.268l.96.278Z"/>
          </svg>

      </a>
      </ng-template>
      <app-userprofile></app-userprofile>

    </mat-tab>

    <mat-tab label="Activities">
      <ng-template mat-tab-label class="tablabel">
        <a routerLink="/dashboard/activities" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
          Activities
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="18.74" fill="none" viewBox="0 0 32 20">
            <path fill="#1F2A44" d="M25.913 7.195a6.07 6.07 0 0 0-1.324.15l-1.075-3.82c.022-1.229 1.089-2.222 2.399-2.222 1.262 0 2.29 1.015 2.29 2.262h.67c0-1.617-1.328-2.932-2.96-2.932-1.591 0-2.903 1.16-3.056 2.642l-8.892 1.368 1.418-2.837H11.84v.67h2.46l-1.204 2.407L9.867 8.52a6.054 6.054 0 0 0-3.78-1.325A6.094 6.094 0 0 0 0 13.282a6.094 6.094 0 0 0 6.087 6.087c3.244 0 5.895-2.552 6.07-5.752h4.323l6.564-9.285.9 3.196c-2.392.821-4.118 3.088-4.118 5.754a6.094 6.094 0 0 0 6.087 6.087A6.094 6.094 0 0 0 32 13.282a6.094 6.094 0 0 0-6.087-6.087ZM13.827 5.342l8.62-1.326-6.041 8.546-2.579-7.22Zm-.577.377 2.582 7.228h-3.675a6.065 6.065 0 0 0-1.787-3.985l2.88-3.243ZM9.925 9.463a5.398 5.398 0 0 1 1.563 3.484H6.833l3.092-3.484ZM6.087 18.7A5.423 5.423 0 0 1 .67 13.282a5.423 5.423 0 0 1 5.417-5.417c1.258 0 2.414.434 3.334 1.156l-4.08 4.596h6.146c-.174 2.83-2.526 5.082-5.4 5.082Zm19.826 0a5.423 5.423 0 0 1-5.417-5.417c0-2.36 1.52-4.367 3.63-5.108l1.464 5.199.645-.182-1.465-5.203a5.423 5.423 0 0 1 6.56 5.294 5.423 5.423 0 0 1-5.417 5.417Z"/>
          </svg>

      </a>
      </ng-template>
      <app-activities></app-activities>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label class="tablabel">
        <a routerLink="/dashboard/routes" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
          Routes
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
            <path stroke="#1F2A44" stroke-linecap="round" d="M2.5 6.436c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h12.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v12.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874c-.428.218-.988.218-2.108.218H5.7c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874c-.218-.427-.218-.988-.218-2.108v-12.6Z"/>
            <path stroke="#1F2A44" d="M12.5 15.766c0 2.158-2.14 3.674-3.073 4.233a.826.826 0 0 1-.854 0C7.64 19.44 5.5 17.924 5.5 15.766c0-2.118 1.696-3.53 3.5-3.53 1.867 0 3.5 1.412 3.5 3.53Zm6 6.47-6-15m9-2-19 4"/>
            <circle cx="9" cy="15.736" r="1" fill="#1F2A44"/>
          </svg>

        </a>
      </ng-template>
      <app-ride-routes/>
    </mat-tab>



  </mat-tab-group>
  <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" #menuTrigger class="btn-add fs-16">
    Add
    <mat-icon>add</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="openDialog(CreategroupComponent)">
      Group
    </button>
    <button mat-menu-item (click)="openDialog(RideComponent)">
      Ride
    </button>
    <button mat-menu-item (click)="openDialog(EventComponent)">
      Event
    </button>
    <button mat-menu-item (click)="openDialog(RouteComponent)">
      Route
    </button>
    <button mat-menu-item (click)="openDialog(InviteMemberComponent)">
      Invite member
    </button>
  </mat-menu>

</div>
