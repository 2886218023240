<div class="sign-up-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div
                class="trinta-form ml-auto mr-auto bg-white border-radius"
            >
                <div class="title text-center">
                    <h3>
                        Register Your Account
                    </h3>
                    <p class="text-black fs-15">
                        Already have an account? <a routerLink="/authentication" class="text-primary fw-medium">Sign in here</a>
                    </p>
                </div>
                <div class="signup-with-socials">
                    <button type="button" mat-button>
                        <img src="assets/images/icons/google2.svg" alt="google">
                        Sign Up With Google
                    </button>
                    <div class="or text-center position-relative z-1 text-body">
                        <span class="d-inline-block bg-white">
                            OR
                        </span>
                    </div>
                </div>
                <form [formGroup]="authForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="label d-block fw-medium">
                            Your Name
                        </label>
                        <mat-form-field>
                            <i-feather name="user"></i-feather>
                            <mat-label>Enter your name</mat-label>
                            <input matInput type="text" id="name" formControlName="name">
                        </mat-form-field>
                        <div class="error text-danger" *ngIf="authForm.get('name')!.hasError('required') && authForm.get('name')!.touched">
                            Name is required.
                        </div>
                        <div class="error text-danger" *ngIf="authForm.get('name')!.hasError('name') && authForm.get('name')!.touched">
                            Please enter a valid name.
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="label d-block fw-medium">
                            Email Address
                        </label>
                        <mat-form-field>
                            <i-feather name="mail"></i-feather>
                            <mat-label>Enter email address</mat-label>
                            <input matInput type="email" id="email" formControlName="email">
                        </mat-form-field>
                        <div class="error text-danger" *ngIf="authForm.get('email')!.hasError('required') && authForm.get('email')!.touched">
                            Email is required.
                        </div>
                        <div class="error text-danger" *ngIf="authForm.get('email')!.hasError('email') && authForm.get('email')!.touched">
                            Please enter a valid email address.
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="label d-block fw-medium">
                            Your Password
                        </label>
                        <mat-form-field>
                            <i-feather name="lock"></i-feather>
                            <mat-label>Enter your password</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" id="password" formControlName="password">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <span class="material-symbols-outlined">
                                    {{hide ? 'visibility_off' : 'visibility'}}
                                </span>
                            </button>
                        </mat-form-field>
                        <div class="error text-danger" *ngIf="authForm.get('password')!.hasError('required') && authForm.get('password')!.touched">
                            Password is required.
                        </div>
                        <div class="error text-danger" *ngIf="authForm.get('password')!.hasError('minlength') && authForm.get('password')!.touched">
                            Password must be at least 8 characters long.
                        </div>
                    </div>
                    <div class="info">
                        <mat-checkbox color="primary">
                            I accept the <a href="javascript:void(0);" class="text-primary">Terms & conditions</a>
                        </mat-checkbox>
                    </div>
                    <button mat-button type="submit" [disabled]="authForm.invalid">
                        Sign Up
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>