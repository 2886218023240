import { Component } from '@angular/core';
import {BasicFormComponent} from "../../basic-elements/basic-form/basic-form.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton, MatMiniFabButton} from "@angular/material/button";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {SimpleFileUploaderComponent} from "../../file-uploader/simple-file-uploader/simple-file-uploader.component";
import {Router} from "@angular/router";
import {MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-invite-member',
  standalone: true,
  imports: [
    BasicFormComponent,
    FormsModule,
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    MatSlideToggle,
    SimpleFileUploaderComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatDialogTitle,
    MatIcon,
    MatMiniFabButton
  ],
  templateUrl: './invite-member.component.html',
  styleUrl: './invite-member.component.scss'
})
export class InviteMemberComponent {
  constructor(
    public dialogRef: MatDialogRef<InviteMemberComponent>,
    private fb: FormBuilder,

  ) {
    this.inviteMemberForm = this.fb.group({
      rideName: ['', [Validators.required]],
      rideType:['', [Validators.required]]
      // outdoor: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  // Password Hide
  hide = true;

  // Form
  inviteMemberForm: FormGroup;

  onSubmit() {
    if (this.inviteMemberForm.valid) {
      console.log(this.inviteMemberForm.value);
    } else {
      console.log('Form is invalid. Please check the fields.');
    }
  }

  // Correctly place the onNoClick method outside the constructor
  onNoClick() {
    this.dialogRef.close();
  }


}
