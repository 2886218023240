import { Component, OnInit } from '@angular/core';
import { DatePipe, NgClass, CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FeathericonsModule } from '../../icons/feathericons/feathericons.module';
import { RouterLink } from '@angular/router';
import { ToggleService } from './toggle.service';
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioButton, MatRadioGroup, MatRadioModule } from "@angular/material/radio";
import { MatInputModule } from "@angular/material/input";
import {MatIcon, MatIconModule} from "@angular/material/icon";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../app/constants';
import { ApiEndpoints } from '../../../app/api-endpoints';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,  // Import CommonModule for *ngFor, *ngIf directives
    FeathericonsModule,
    MatButtonModule,
    MatMenuModule,
    RouterLink,
    NgClass,
    MatSelectModule,  // Import MatSelectModule
    MatOptionModule,  // Import MatOptionModule
    MatFormFieldModule,  // Import MatFormFieldModule
    HttpClientModule,
    MatIcon
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    DatePipe
  ]
})
export class HeaderComponent implements OnInit {
  groups: any[] = [];
  username: string | null = null; // Variable to store the username
  selectedGroupId: number | null = null;
  description: string = '';
  name: string = '';
  invitedRidersOnly: boolean = false;
  socialLinks: string = '';
  locationComment: string = '';
  amount: string = '';

  constructor(
    public toggleService: ToggleService,
    private datePipe: DatePipe,
    private http: HttpClient
  ) {
    this.toggleService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
  }


  // Toggle Service
  isToggled = false;
  toggle() {
    this.toggleService.toggle();
  }


  // Dark Mode
  toggleTheme() {
    this.toggleService.toggleTheme();
  }

  // Current Date
  currentDate: Date = new Date();
  formattedDate: any = this.datePipe.transform(this.currentDate, 'dd MMMM yyyy');

  ngOnInit(): void {
    
    const userObj = localStorage.getItem('userObj');
    if (userObj) {
      try {
        const parsedUserObj = JSON.parse(userObj);
        this.username = parsedUserObj.firstname; // Assuming username is stored under 'name' key
        this.loadGroups();
      } catch (error) {
        console.error("Error parsing JSON from localStorage:", error);
      }
    }
  }


  loadGroups() {
    const token = localStorage.getItem('angular17token');
    if (token) {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      this.http.get(BASE_URL + ApiEndpoints.getGroupsByUser, { headers: headers }).subscribe(
        (data: any) => {
          this.groups = data; // Assuming data is an array of objects with properties 'value' and 'label'
          if (this.groups.length > 0) {
            const storedGroupId = localStorage.getItem('selectedGroupId');
            if (storedGroupId !== null) {
              this.selectedGroupId = parseInt(storedGroupId, 10); // Convert to number
            } else {
              this.selectedGroupId = this.groups[0].la_RidingGroup_ID; // Auto-select the first group
              localStorage.setItem('selectedGroupId', this.groups[0].la_RidingGroup_ID); // Convert to string before storing
            }
          }
        },
        error => {
          console.log('Error fetching data:', error);
        }
      );
    } else {
      console.log('No token found in local storage.');
    }
  }



  onGroupChange(event: any): void {
    const selectedGroupId = event.value;
    console.log("Selected Group ID:", selectedGroupId);
    // Save to local storage
    localStorage.setItem('selectedGroupId', selectedGroupId);

    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    var groupdId = localStorage.getItem('selectedGroupId');
    this.http.get<any>(BASE_URL + ApiEndpoints.getGroupsByID + selectedGroupId, { headers: headers }).subscribe(
      (data) => {
        console.log("group data", data)
        this.description = data[0].description;
        this.name = data[0].name;
        this.invitedRidersOnly = data[0].invitedRidersOnly;
        this.socialLinks = data[0].socialLinks;
        this.locationComment = data[0].locationComment;
        this.amount = data[0].amount;
        window.location.reload();
      },
      (error) => {
        console.error('Error fetching rides data:', error);
      }
    );
  }
}
