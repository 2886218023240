export class ApiEndpoints {
    static readonly login = "accounts/login";
    static readonly createAccount = "accounts/register";
    static readonly changePassword = "accounts/changePassword";
    static readonly deactivateAccount = "accounts/deactivateAccount";
  
    static readonly createProfile = "profile/createProfile";
    static readonly createRidingProfile = "profile/createRidingProfile";
    static readonly createBike = "bikes/createBike";
    static readonly editBike = "bikes/editBike";
    static readonly listBikes = "bikes/list";
    static readonly joinGroup = "groups/joinGroup";
    static readonly createService = "service/createService";
    static readonly joinRide = "rides/joinRide";
    static readonly leaveRide = "rides/leaveRide";
    static readonly createGroup = "groups/createRidingGroup";
    static readonly createEvent = "events/createEvent";
    static readonly updateGroup = "groups/createRidingGroup";
    static readonly getGroupMembers = "groups/getGroupMembers?group_id=";
    static readonly ridesHistoryPerGroup = "rides/ridesHistoryPerGroup?groupId=";
    static readonly ridesUpComingPerGroup = "rides/ridesUpComingPerGroup?groupId=";
    static readonly cancelRide = "rides/cancelRide?rideId=";
    static readonly ridersPerRide = "rides/ridersPerRide/";
    
    
    
    
    static readonly createRoute = "rides/createRoute";

    static readonly createRide = "rides/createRide";
    static readonly createCleanPrepSchedule = "cleanprep/createCleanPrepSchedule";
    static readonly createCheckoutRequest = "checkout/request/create";
    static readonly cancelServiceOrder = "service/cancelOrderService";
    static readonly scheduleService = "service/scheduleService";
    static readonly createUpdatePrivacyProfile = "profile/createUpdatePrivacyProfile";
    static readonly createUpdateUomProfile = "profile/createUpdateUomProfile";
    static readonly createUpdateNotificationsProfile = "profile/createUpdateNotificationsProfile";
    static readonly joinEvent = "events/joinEvent";
    static readonly deactivateEvent = "events/deactivateEvent";
    static readonly leaveGroup = "groups/leaveGroup";
  
    // Additional Endpoints
    static readonly getActiveCountries = "common/countries";
    static readonly getActiveStates = "profile/states";
    static readonly getActiveGender = "profile/gender";
    static readonly getCategories = "service/getProductCategories";
    static readonly getActiveCity = "profile/cities";
    static readonly getActiveClubhouses = "profile/clubHouses";
    static readonly getActiveRidingStyle = "profile/ridingStyle";
    static readonly getActivePace = "profile/pace";
    // static readonly getActivePreferredType = "profile/preferredTypes";

    static readonly getActiveridingPaceList = "groupSetup/ridingPaceList";
    static readonly getActiveridingTypeList = "groupSetup/ridingTypeList";
    static readonly getActiveridingLevelList = "groupSetup/ridingLevelList";

    static readonly getActivePreferredType = "profile/preferredTypes";
    static readonly getActiveAnnualMileage = "profile/annualMileage";
    static readonly getCitiesByState = "profile/citiesByState/";
    static readonly listBikesPerUsers = "profile/bikes";
    static readonly listBikesPerUser = "bikes/bikesPerUser?";
    static readonly getGroups = "groups/ridingGroup";
    static readonly getGroupsByUser = "groups/getGroupJoined";
    static readonly optionsServiceList = "groups/ridingGroup";
    static readonly getGroup = "groups/getGroups";
    static readonly getGroupsNotJoined = "groups/getGroupNotJoined";
    static readonly getGroupsJoinedByUser = "groups/getGroupJoined";
    static readonly totalRideCountPerUser = "rides/rideCountForUser";
    static readonly getUserProfile = "profile/getUserProfile";
    static readonly suggestedRidesPerUser = "rides/suggestedRides";
    static readonly currentServiceRequest = "service/getServiceRequestbyUser?partner_id=";
    static readonly getServiceHistoryByUser = "service/getServiceHistorybyUser?partner_id=";
    static readonly suggestedServiceRequest = "service/getSuggestedServiceRequestbyUser?partner_id=";
    static readonly ridesPerUser = "rides/ridesJoined";
    static readonly allActiveRides = "rides/list";
    static readonly getGroupsByID = "groups/getGroupsByID/";
    static readonly allEventsList = "events/upcomingEvents";
    static readonly upcomingEventsJoined = "events/upcomingEventsJoined";
    static readonly upcomingEventsNotJoined = "events/upcomingEventsNotJoined";
    static readonly ridingGroup = "groups/ridingGroup";
    static readonly levels = "profile/levels";
    static readonly purpose = "profile/purpose";
    static readonly recurrence = "profile/recurrence";
    static readonly notifications = "common/notifications";
    static readonly notificationsByUser = "service/getServiceNotification?partner_id=";
    static readonly notificationsPerUser = "profile/notificationsProfilePerUser";
    static readonly privacyProfilePerUser = "profile/privacyProfilePerUser?cbPartnerId=";
    static readonly uomProfilePerUser = "profile/uomProfilePerUser?cbPartnerId=";
    static readonly notificationsProfilePerUser = "profile/notificationsProfilePerUser?cbPartnerId=";
    static readonly eventTypes = "profile/eventTypes";
    static readonly pastRidesList = "rides/pastRidesList";


    

    
  }
  