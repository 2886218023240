<!-- Gallery Style - 1 -->
<mat-card class="trinta-card gallery-card mb-25 bg-white border-none d-block gallerysectiontab">
   
    <mat-card-content class="gallerysectiontab">
        <div class="row gallery-list gallerysectiontab">
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/group.png" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/group.png" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/group.png" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/group.png" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/group.png" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/group.png" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/group.png" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/group.png" class="border-radius" alt="gallery-image">
            </div>
        </div>
    </mat-card-content>
</mat-card>