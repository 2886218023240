<mat-card-content>
    <!-- <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="membersectiontab"> -->
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="membersectiontab" [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)="onTabChange($event)">

        <mat-tab label="Members">
         
            <div class="container membersectiontab">
              <div class="row leftimgsection">
                <!-- Left -->
                <div class="col-xl-3 col-xxl-3 col-xxxl-3 col-lg-3 col-sm-3">
                  <mat-card-header class="leaderboardsection" style="padding-bottom: 9%; padding-left: 0px;">
                    <mat-card-title>
                        <h3 class="mt-0 mb-0">
                            Leaderboard
                        </h3>
                    </mat-card-title>
                </mat-card-header>
                  <img src="assets/bgimag1.png" class="profile-img" style="width: 100%;">
                </div>
                <!-- Center -->
                <div class="col-xl-5 col-xxl-5 col-xxxl-5 col-lg-5 col-sm-5">
                  <!-- <app-members></app-members> -->
                  <app-leaderboard />

                </div>
                <!-- Right -->
                <div class="col-xl-4 col-xxl-4 col-xxxl-4 col-lg-4 col-sm-4">
                  <app-invites></app-invites>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-8 col-sm-12">
                  <!-- New Component Below 3 and 5 Column -->
                  <app-members></app-members>
                </div>
                <div class="col-xl-4 col-lg-4 col-sm-12">
                  <!-- Right component aligned with previous row's right column -->
                  <app-waitlist></app-waitlist>
                </div>
              </div>
            </div>
          </mat-tab>
          
        <mat-tab label="Group details">
          <div class="container membersectiontab">

            <div class="row">
               

                <div class="col-xl-12 col-xxl-12 col-xxxl-12 col-lg-12 col-sm-12">

                    <app-groupdetails/>
    
                    </div>
                  </div>
            </div>
        </mat-tab>
        <mat-tab label="Payments">
          <div class="container paymentssectiontab">

            <div class="row">
                <div class="col-xl-12 col-xxl-12 col-xxxl-12 col-lg-12 col-sm-12">
                    <app-payments/>
                </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Feed">
          <div class="container feedsectiontab">

            <div class="row">
                <div class="col-xl-8 col-xxl-8 col-xxxl-8 col-lg-8 col-sm-8">
                  <app-scialfeeds/>
                </div>

                <div class="col-xl-4 col-xxl-4 col-xxxl-4 col-lg-4 col-sm-4">
                      <app-chat/>
                </div>

                </div>
            </div>
        </mat-tab>
        <mat-tab label="Gallery">
          <div class="container gallerysectiontab">

            <div class="row">
                <div class="col-xl-12 col-xxl-12 col-xxxl-12 col-lg-12 col-sm-12">

                  <app-gallery/>

                  </div> 
                </div> 

            </div>
        </mat-tab>
    </mat-tab-group>
</mat-card-content>