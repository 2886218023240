import {Component, OnInit,Inject} from '@angular/core';
import {FeatherModule} from "angular-feather";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatCard, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {data} from "../../../charts/apexcharts/datetime-area-chart/series-data";
import {DialogData} from "../../../ui-elements/dialog/basic-dialog/basic-dialog.component";
import {Router, RouterLink} from "@angular/router";
import {BasicFormComponent} from "../../basic-elements/basic-form/basic-form.component";
import {MatOption} from "@angular/material/autocomplete";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {MatSelect} from "@angular/material/select";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {SimpleFileUploaderComponent} from "../../file-uploader/simple-file-uploader/simple-file-uploader.component";
import {MatIcon} from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { provideNativeDateAdapter } from '@angular/material/core';
import { DateAdapter } from '@angular/material/core';





@Component({
  selector: 'app-event',
  standalone: true,
    imports: [
        FeatherModule,
        FormsModule,
        MatButton,
        MatCard,
        MatCardContent,
        MatCardHeader,
        MatCardTitle,
        MatCheckbox,
        MatFormField,
        MatIconButton,
        MatInput,
        MatLabel,
        MatSuffix,
        MatDialogActions,
        MatDialogContent,
        MatDialogTitle,
        MatDialogClose,
        RouterLink,
        BasicFormComponent,
        MatOption,
        MatRadioButton,
        MatRadioGroup,
        MatSelect,
        MatSlideToggle,
        ReactiveFormsModule,
        MatDatepicker,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatHint,
        SimpleFileUploaderComponent,
        MatIcon,
        ReactiveFormsModule,
        MatFormField,
        MatRadioGroup, FileUploadModule,
        MatRadioButton,
        MatInput,
        MatLabel,
        MatSelect,
        MatTooltip,
        HttpClientModule,
        CommonModule,
        RouterLink
    ],
  templateUrl: './event.component.html',
  providers: [provideNativeDateAdapter()],
  styleUrl: './event.component.scss'
})
export class EventComponent implements OnInit {

  eventTypes: any[] = [];
  logoBase64: string | null = null;
  displayDate: string | null = null;
  displayEndDate: string | null = null;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    public dialogRef: MatDialogRef<EventComponent>,
    private dateAdapter: DateAdapter<Date>


  ) {
    this.createEventForm= this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]], // Assuming optional
      amount: ['', [Validators.required]],// Assuming optional
      la_Event_Type_ID: ['', [Validators.required]] ,
      url: ['', [Validators.required]],
      longitude: [''] ,
      latitude: [''], // Assuming optional
      location: ['', [Validators.required]],
      image: [''] ,
      date: ['', [Validators.required]],
      isRecurrent: [''], // Assuming optional
      organizer: ['', [Validators.required]],
      time: ['', [Validators.required]],
      groupSize: ['', [Validators.required]],
      address: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
    });
    this.dateAdapter.setLocale('en-US'); // Set the locale to en-US to ensure the date format

  }

  // Password Hide
  hide = true;
  createEventForm: FormGroup;


  onDateChange(event: any): void {
    const date = event.value;
    if (date) {
      const formattedDate = this.formatDate(date);
      this.displayDate = formattedDate;
      this.createEventForm.get('date')?.setValue(date);
    }
  }

  onDateChangeEnddate(event: any): void {
    const date = event.value;
    if (date) {
      const formattedEndDate = this.formatDate(date);
      this.displayEndDate = formattedEndDate;
      console.log("formattedEndDate", formattedEndDate)
      this.createEventForm.get('endDate')?.setValue(date);
    }
  }
 
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  // Form

  ngOnInit(): void {
    this.loadeventTypes();
  }

  onNoClick() {
    this.dialogRef.close();
    // this.loadPurposes()
  }

  onSubmit() {
    if (this.createEventForm.valid) {
      console.log(this.createEventForm.value);

      var cbPartnerId = "";
      var userobj = localStorage.getItem("userObj");
      if (userobj) {
        try {
          var parsedUserObj = JSON.parse(userobj);

          cbPartnerId = parsedUserObj.cbPartnerId;
          console.log(parsedUserObj);
        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
        }
      } else {
        console.warn("No userObj found in localStorage.");
      }



      // Construct the login object
      const requestBody = {
        "title": this.createEventForm.get('title')?.value,
        "description": this.createEventForm.get('description')?.value,
        "image": this.logoBase64, // You may need to handle image upload separately
        "longitude": 0,
        "latitude": 0, // Assuming this is hardcoded to true
        "location": this.createEventForm.get('location')?.value,
        "amount":  this.createEventForm.get('amount')?.value, // You may need to handle logo upload separately
        "date": this.displayDate,
        "la_Event_Type_ID": this.createEventForm.get('la_Event_Type_ID')?.value,
        "isRecurrent": this.createEventForm.get('isRecurrent')?.value,
        "url": this.createEventForm.get('url')?.value,
        "organizer": this.createEventForm.get('organizer')?.value,
        "time": "2024-07-24 21:00:00",
        "address": this.createEventForm.get('address')?.value,
        "endDate": this.displayEndDate,
        "groupSize": this.createEventForm.get('groupSize')?.value,
      };



      var obj =
        console.log("userobjuserobj", userobj)
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

      this.http.post(BASE_URL + ApiEndpoints.createEvent, requestBody, { headers: headers }).subscribe(
        (res: any) => {
          if (res) {
            alert("Event created successfully");
            // this.router.navigate(['/dashboard']);
            window.location.reload();

          } else {
            // Handle other cases where response is not as expected
            console.error("Unexpected response:", res);
            alert("Error, Failed");
          }
        },
        (error) => {
          if (error.status === 401) {
            // Handle 401 Unauthorized error
            console.log("Unauthorized:", error.status);
            alert("Unauthorized");
          } else if (error.status === 500) {
            // Handle 401 Unauthorized error
            console.log("Unauthorized:", error.status);
            alert("Internal server error");
          } else {
            // Handle other errors
            console.error("Error logging in:", error);
            alert("Failed creating record. Please try again later.");
          }
        }
      );

    } else {

      console.log('Form is invalid. Please check the fields.');
    }
  }



  loadeventTypes() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.eventTypes, { headers: headers }).subscribe(
      (data: any) => {
        this.eventTypes = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }

  onFileSelected(event: Event, controlName: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        this.createEventForm.patchValue({
          [controlName]: base64String
        });
        console.log(`${controlName} base64String:`, base64String);
        if (controlName === 'logo') {
          this.logoBase64 = base64String;
        } 
      };
      reader.readAsDataURL(file);
    }
  }
}


