import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { TransactionsHistoryComponent } from '../transactions-history/transactions-history.component';
import { MembersComponent } from '../members/members.component';
import { InvitesComponent } from '../invites/invites.component';
import { LeaderboardComponent } from '../leaderboard/leaderboard.component';
import { WaitlistComponent } from '../waitlist/waitlist.component';
import { GroupdetailsComponent } from '../groupdetails/groupdetails.component';
import { UpcomingridesComponent } from '../upcomingrides/upcomingrides.component';
import { UpcomingrideshistoryComponent } from '../upcomingrideshistory/upcomingrideshistory.component';
import { CalendarComponent } from '../../project-management/calendar/calendar.component';
import {RideRouteListComponent} from "../ride-routes/ride-route-list/ride-route-list.component";






@Component({
  selector: 'app-activities',
  standalone: true,
  imports: [RouterLink, WaitlistComponent, CalendarComponent, GroupdetailsComponent, MatCardModule, MatButtonModule, MatMenuModule, MembersComponent, MatTabsModule, TransactionsHistoryComponent, InvitesComponent, LeaderboardComponent, UpcomingridesComponent, UpcomingrideshistoryComponent, RideRouteListComponent],
  templateUrl: './activities.component.html',
  styleUrl: './activities.component.scss'
})


export class ActivitiesComponent {
}
