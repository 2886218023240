<mat-card class="trinta-card mb-25 bg-white border-none d-block">
  <mat-card-header class="d-flex justify-content-between">
    <mat-card-title>
      <h5 class="mt-0  mb-0">
        Routes
      </h5>
    </mat-card-title>
    <button mat-mini-fab matTooltip="View routes list" aria-label="Button with list icon" (click)="openRouteListPage()" class="">
      <i class="material-icons">list</i>
    </button>

  </mat-card-header>
  <mat-card-content>
    <mat-list role="list">
<!--      Uncomment commented out lines-->
<!--      @for (rideRoute of rideRoutes; track rideRoute) {-->
        <mat-list-item class="d-flex">
          <div matListItemTitle class="text-black col-sm-6">Point Break</div>
<!--          <div matListItemTitle class="text-black col-sm-6">{{rideRoute.name}}</div>-->

        </mat-list-item>

<!--      }-->

    </mat-list>
  </mat-card-content>
</mat-card>
