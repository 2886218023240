import { Component } from '@angular/core';
import {MatCard, MatCardContent} from "@angular/material/card";

@Component({
  selector: 'app-group-overview-stats',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent
  ],
  templateUrl: './group-overview-stats.component.html',
  styleUrl: './group-overview-stats.component.scss'
})
export class GroupOverviewStatsComponent {

}
