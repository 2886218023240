import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';



@Component({
  selector: 'app-ridcalendar',
  standalone: true,
  imports: [RouterLink, MatCardModule, MatButtonModule, MatMenuModule, MatDatepickerModule, MatNativeDateModule],

  templateUrl: './ridcalendar.component.html',
  styleUrl: './ridcalendar.component.scss'
})
export class RidcalendarComponent {
  selected: Date | null;

}
