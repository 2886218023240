
<div class="title text-center pop-up">
    <h2 mat-dialog-title class="fw-semibold">Edit Group</h2>
    <button mat-mini-fab color="primary" (click)="onNoClick()" class="cancel">
      <mat-icon>close</mat-icon>
    </button>
</div>
  <div mat-dialog-content>
    <form [formGroup]="createRideForm" (ngSubmit)="onSubmit()" class="d-flex form-group flex-column createredforms">
      <mat-label>Name</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput type="text" formControlName="name" id="name" name="name">
    </mat-form-field>

    <mat-label>Description</mat-label>
    <mat-form-field class="custom-form-field">
      <textarea rows="4" matInput type="text" formControlName="description" id="description" name="description"></textarea>
    </mat-form-field>
    

 


    <label class="flabel">State</label>
    <mat-form-field class="custom-form-field">
    <mat-select formControlName="stateId" id="stateId" name="stateId" [ngClass]="{'invalid-input': createRideForm.get('stateId')?.invalid && createRideForm.get('stateId')?.touched}">
      <mat-option value="" disabled>Select State</mat-option>
      <mat-option *ngFor="let state of states" [value]="state.id">{{ state.name }}</mat-option>
    </mat-select>
  </mat-form-field>



     <label class="flabel">City</label>
     <mat-form-field class="custom-form-field">
    <mat-select formControlName="cityId" id="cityId" name="cityId" [ngClass]="{'invalid-input': createRideForm.get('cityId')?.invalid && createRideForm.get('cityId')?.touched}">
      <mat-option value="" disabled>Select City</mat-option>
      <mat-option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</mat-option>
    </mat-select>
  </mat-form-field>



    <mat-form-field class="custom-form-field" style="display: none;">
      <input matInput formControlName="locationComment" name="locationComment" id="locationComment" value="N/A">
    </mat-form-field>

     <label class="flabel">Typical Pace</label>
     <mat-form-field class="custom-form-field">
    <mat-select formControlName="la_TypicalPace_ID" id="la_TypicalPace_ID" name="la_TypicalPace_ID" [ngClass]="{'invalid-input': createRideForm.get('la_TypicalPace_ID')?.invalid && createRideForm.get('la_TypicalPace_ID')?.touched}"> 
      <mat-option value="" disabled>Select Pace</mat-option>
      <mat-option *ngFor="let typicalpace of typicalpaces" [value]="typicalpace.la_TypicalPace_ID">{{ typicalpace.name
        }}</mat-option>
    </mat-select>
  </mat-form-field>


     <label class="flabel">Type of Ride</label>
     <mat-form-field class="custom-form-field">
    <mat-select formControlName="la_RidingType_ID" id="la_RidingType_ID" name="la_RidingType_ID"  [ngClass]="{'invalid-input': createRideForm.get('la_RidingType_ID')?.invalid && createRideForm.get('la_RidingType_ID')?.touched}">
      <mat-option *ngFor="let rideType of rideTypes" [value]="rideType.id">{{ rideType.name }}</mat-option>
    </mat-select>
  </mat-form-field>


     <label class="flabel">Difficulty Level</label>
     <mat-form-field class="custom-form-field">
    <mat-select formControlName="la_RidingLevel_ID" id="la_RidingLevel_ID" name="la_RidingLevel_ID"  [ngClass]="{'invalid-input': createRideForm.get('la_RidingLevel_ID')?.invalid && createRideForm.get('la_RidingLevel_ID')?.touched}">
      <!-- <mat-option value="" disabled>Select Difficulty Level</mat-option> -->
      <mat-option *ngFor="let level of levels" [value]="level.id">{{ level.name }}</mat-option>
    </mat-select>
  </mat-form-field>



     <label class="flabel">Annual Mileage</label>
     <mat-form-field class="custom-form-field">
    <mat-select formControlName="la_AnnualMileage_ID" id="la_AnnualMileage_ID" name="la_AnnualMileage_ID"   [ngClass]="{'invalid-input': createRideForm.get('la_AnnualMileage_ID')?.invalid && createRideForm.get('la_AnnualMileage_ID')?.touched}">
      <mat-option value="" disabled>Select Annual Mileage</mat-option>
      <mat-option *ngFor="let mileage of mileages" [value]="mileage.id">{{ mileage.name }}</mat-option>
    </mat-select>
  </mat-form-field>


    <div class="row custom-form-field" style="padding-top: 12px;">
      <div class="col-sm-5">
        <label class="flabel">Restrictions</label>
      </div>
      <div class="row col-sm-7">
        <mat-radio-group
          aria-label="Select an option"
          class="col-sm-6 d-flex justify-content-between flex-wrap"
          formControlName="invitedRidersOnly"
          id="invitedRidersOnly"
          name="invitedRidersOnly"
          (change)="onRestrictionChange('invitedRidersOnly', $event)"
        >
          <mat-radio-button [value]="true">Invites only</mat-radio-button>
        </mat-radio-group>
    
        <mat-radio-group
          aria-label="Select an option"
          class="col-sm-6 d-flex justify-content-between flex-wrap"
          formControlName="adminApproval"
          id="adminApproval"
          name="adminApproval"
          (change)="onRestrictionChange('adminApproval', $event)"
        >
          <mat-radio-button [value]="true">Admin approval</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    

    <div class="row custom-form-field" style="padding-top: 12px;">
      <div class="col-sm-5">
         <label class="flabel">Allow members to create/post rides?</label>
      </div>
      <div class="row col-sm-7">
        <mat-radio-group aria-label="Select an option" class="col-sm-6 d-flex justify-content-between flex-wrap"
          formControlName="allowMemberPost" id="allowMemberPost" name="allowMemberPost">
          <mat-radio-button value="true">Yes</mat-radio-button>
          <mat-radio-button value="false">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

     <label class="flabel">Cost</label>
     <mat-form-field class="custom-form-field">
    <input matInput type="number" formControlName="amount" id="amount" name="amount" >
  </mat-form-field>

     <label class="flabel">Logo</label>
    <div class="upload-button-container custom-form-field">
      <input type="file" (change)="onFileSelected($event, 'logo')" accept="image/*" placeholder="Logo">
      <mat-icon class="upload-icon">cloud_upload</mat-icon>
    </div>

     <label class="flabel">Cover Photo</label>
    <div class="upload-button-container custom-form-field">
      <input type="file" (change)="onFileSelected($event, 'coverphoto')" accept="image/*">
      <mat-icon class="upload-icon">cloud_upload</mat-icon>
    </div>

     <label>Website link</label>
     <mat-form-field class="custom-form-field">

    <input matInput formControlName="url" name="url" id="url">
  </mat-form-field>

    <label class="flabel">Facebook link</label>
    <mat-form-field class="custom-form-field">
    <input matInput formControlName="faceBook" name="faceBook" id="faceBook">
  </mat-form-field>

     <label class="flabel">X link</label>
     <mat-form-field class="custom-form-field">
    <input matInput formControlName="x" name="x" id="x">
  </mat-form-field>

     <label class="flabel">Instagram link</label>
     <mat-form-field class="custom-form-field">

    <input matInput formControlName="instagram" name="instagram" id="instagram">
  </mat-form-field>

      <div class="button-container submitbtn" style="padding-top: 16px; padding-bottom: 16px">
        <button mat-raised-button color="primary" type="submit" class="col-sm-5">Update</button>
      </div>
    </form>
  </div>
