<div class="title text-center">
  <button mat-mini-fab color="primary" (click)="onNoClick()" class="cancel">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="viewDetailsData">
    <div>
        <h2 style="text-align: center;" class="indooroutdoor">{{ createRideForm.get('preferredBikeTypes')?.value }}</h2>
    </div>
    <div class="row">
        <div class="column">
          <h5>
            <span class="label">Time:</span>
                <span class="datavalue">{{ formatTimeTo12Hour(createRideForm.get('time')?.value) }}</span>
              </h5>
            </div>
        <div class="column"></div>
    </div>

    <div class="row">
        <div class="column">
            <h5>
                <span class="label">Starting Location:</span>
                <span class="datavalue"> {{ createRideForm.get('startLocation')?.value }}</span>
            </h5>
        </div>
        <div class="column">
            <h5>
                <span class="label">Ride Type:</span>
                <span class="datavalue">{{ createRideForm.get('preferredBikeTypes')?.value }}</span>
            </h5>
        </div>
    </div>

    <div class="row">
        <div class="column">
            <h5>
                <span class="label">Pace:</span>
                <span class="datavalue">{{ createRideForm.get('pace')?.value }}</span>
            </h5>
        </div>
        <div class="column">
            <h5>
                <span class="label">Level:</span>
                <span  class="datavalue">{{ createRideForm.get('level')?.value }}</span>
            </h5>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <h5>
                <span class="label">Purpose:</span>
                <span class="datavalue">{{ createRideForm.get('purpose')?.value }}</span>
            </h5>
        </div>
        <div class="column">
            <h5>
                <span class="label">Recurrence:</span>
                <span class="datavalue">{{ createRideForm.get('recurrence')?.value }}</span>
            </h5>
        </div>
    </div>

    <div class="row">
      <div class="column">
          <h5>
              <span class="label">InDoor Ride:</span>
              <span class="datavalue">{{ createRideForm.get('inDoor')?.value }}</span>
          </h5>
      </div>

      <div class="column">
        <h5>
            <span class="label">OutDoor Ride:</span>
            <span class="datavalue">{{ createRideForm.get('outDoor')?.value }}</span>
        </h5>
    </div>
  </div>

    <div class="row">
        <div class="column">
            <h5>
                <span class="label">Ride Leader:</span>
                <span class="datavalue">{{ createRideForm.get('rideLeader')?.value }}</span>
            </h5>
        </div>
    </div>
    <div class="row">
      <div class="column">
          <h5>
              <span class="label">Description</span>
              <span class="datavalue">{{ createRideForm.get('description')?.value }}</span>
          </h5>
      </div>
  </div>
</div>


    <mat-card-content style="padding: 20px 0px 16px 0px !important;">
        <h3 style="font-size: 16px;">Registrants</h3>
      <div class="recent-orders-table">
          <div class="table-responsive">
              <table mat-table [dataSource]="dataSource.data">

                  <!-- user Column -->
                  <ng-container matColumnDef="profilePhoto">
                      <th mat-header-cell *matHeaderCellDef>
                          Profile
                      </th>
                      <td mat-cell *matCellDef="let element">
                          <div class="product-info d-flex align-items-center">
                              <a routerLink="/" class="image d-block">
                                  <img src="../../../../assets/pr1.png" alt="product-image">
                              </a>
                              <div class="info">
                                
                              </div>
                          </div>
                      </td>
                  </ng-container>
                 

                  <!-- Quantity Column -->
                  <ng-container matColumnDef="firstname">
                      <th mat-header-cell *matHeaderCellDef>
                          First Name
                      </th>
                      <td mat-cell *matCellDef="let element">
                          {{element.firstname}}
                      </td>
                  </ng-container>

                  <!-- groupride Column -->
                  <ng-container matColumnDef="lastname">
                      <th mat-header-cell *matHeaderCellDef>
                          Last Name
                      </th>
                      <td mat-cell *matCellDef="let element">
                          {{element.lastname}}
                      </td>
                  </ng-container>

                  <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>
                        Phone Number
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.phone}}
                    </td>
                </ng-container>

               

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              </table>
          </div>
          <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
      </div>
  </mat-card-content>
  </div>
  