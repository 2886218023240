<!-- Change Password -->
<mat-card
    class="trinta-card change-password-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Change Password
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <div class="row">
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Old Password
                    </label>
                    <mat-form-field>
                        <i-feather name="lock"></i-feather>
                        <mat-label>Enter old password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <span class="material-symbols-outlined">
                                {{hide ? 'visibility_off' : 'visibility'}}
                            </span>
                        </button>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        New Password
                    </label>
                    <mat-form-field>
                        <i-feather name="lock"></i-feather>
                        <!-- <mat-label>Enter new password</mat-label> -->
                        <input matInput [type]="hide2 ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                            <span class="material-symbols-outlined">
                                {{hide2 ? 'visibility_off' : 'visibility'}}
                            </span>
                        </button>
                    </mat-form-field>
                </div>
                <div class="col-lg-12">
                    <label class="label d-block fw-medium mb-10">
                        Confirm Password
                    </label>
                    <mat-form-field>
                        <i-feather name="lock"></i-feather>
                        <!-- <mat-label>Enter confirm password</mat-label> -->
                        <input matInput [type]="hide3 ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="hide3 = !hide3" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide3">
                            <span class="material-symbols-outlined">
                                {{hide3 ? 'visibility_off' : 'visibility'}}
                            </span>
                        </button>
                    </mat-form-field>
                </div>
            </div>
            <div class="save-password-btn">
                <button mat-button type="button">
                    Change Password
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>

<!-- Login History -->
<mat-card
    class="trinta-card login-history-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Login History
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul class="p-0 m-0 list-unstyled">
            <li class="d-md-flex justify-content-between align-items-center">
                <div>
                    <h6>
                        Google Pixel 7 Pro
                    </h6>
                    <p class="text-black">
                        Los Angeles, United States - March 16 at 2:47 PM
                    </p>
                    <span class="d-block text-body">
                        54 mins ago
                    </span>
                </div>
                <button type="button" mat-button>
                    Log Out
                </button>
            </li>
            <li class="d-md-flex justify-content-between align-items-center">
                <div>
                    <h6>
                        MacBook Air M2
                    </h6>
                    <p class="text-black">
                        Los Angeles, United States - March 15 at 11:11 AM
                    </p>
                    <span class="d-block text-body">
                        1 day ago
                    </span>
                </div>
                <button type="button" mat-button>
                    Log Out
                </button>
            </li>
        </ul>
    </mat-card-content>
</mat-card>